import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { AuthService } from "./auth.service";
import { BroadcastChannelService } from "./broadcast-channel.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { connectionSuccessfulMessage } from "@store/constants";
import { BehaviorSubject, Subject, combineLatest } from "rxjs";
import { map } from "rxjs/operators"
import * as moment from "moment";
import {createThunderWebSocket, ThunderWebSocket} from "@models/thunder-web-socket";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { FA_BOARD_SCENARIO_TYPES } from "apps/contract/_constants/constants";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ContractService extends BaseService {

  assetListSubject: Subject<any> = new Subject();
  capHoldsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  contractSocket: ThunderWebSocket;
  currentContractSocket: ThunderWebSocket;
  currentContractSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  channelNameSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  errorSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  exceptionsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  externalDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  faBoardTeamSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  filterSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  focusGroupEntityGroupingSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  focusGroupMembershipSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  freeAgentSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  playerInterestSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  qualifyingOfferSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  scenarioSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  scenarioChangeSubject: Subject<number> = new Subject();
  selectedDraftAssetSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedScenarioID: BehaviorSubject<number> = new BehaviorSubject(null);
  showFABoard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  teamListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  teamBudgetSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  tempTransactionStatusSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  offersSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  transactionLedgerSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  validationSuccessSubject: Subject<boolean> = new Subject();
  lastCurrentContractData: any = {};
  lastTeamBudget: any = {};
  displayTransactionLedger: any[] = [];
  displayLeagueBudget: any = {};
  displayTeamBudget: any = {};
  displayTeamLedger: any = {};

  constructor(protected authService: AuthService,
              protected http: HttpClient,
              protected router: Router,
              protected broadcastChannelService: BroadcastChannelService) {
    super(http, broadcastChannelService);
    this.updateShowFABoard();
  }

  createContractSocket(minDate: string, maxDate: string, selectedTransactionFilter: string, scenarioID: number) {
    this.contractSocket = createThunderWebSocket(this.contractSocket, `contract/${scenarioID}/${minDate}/${maxDate}/${selectedTransactionFilter}`, this.broadcastChannelService);
    return this.contractSocket;
  }

  connectContractSocket(minDate: string, maxDate: string, selectedTransactionFilter: string, scenarioID?: number) {
    scenarioID = scenarioID || 0;
    let contractSocket = this.createContractSocket(minDate, maxDate, selectedTransactionFilter, scenarioID);

    contractSocket.messages.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event.data) {
        const contractData = JSON.parse(event.data);
        if (contractData?.permsError) {
          this.router.navigate(['contract', 'ledger'], {
            queryParams: {scenarioID: 'null'},
            queryParamsHandling: 'merge',
          });
          this.selectedScenarioID.next(null);
          this.closeContractSocket(false);
          this.connectContractSocket(minDate, maxDate, selectedTransactionFilter, null);
        }
        else if (contractData?.errors) {
          this.validationSuccessSubject.next(false);
          this.errorSubject.next(contractData.errors);
        }
        else if (contractData?.validationSuccess) {
          this.validationSuccessSubject.next(contractData.validationSuccess);
        }
        else if (contractData?.scenarios) {
          this.scenarioSubject.next(contractData['scenarios']);
        }
        else {
          this.loadingSubject.next(false);
        }
        if (contractData?.channelName) {
          let payload = {};
          payload[connectionSuccessfulMessage] = {'minDate': minDate, 'maxDate': maxDate, 'selectedTransactionFilter': selectedTransactionFilter, 'scenarioID': scenarioID};

          this.sendContractSocketAction(payload);
          this.loadingSubject.next(true);
          this.channelNameSubject.next(contractData['channelName']);
        }
        if (contractData?.teamBudget) {
          if (contractData['teamBudget']['data']) {
            this.lastTeamBudget = contractData['teamBudget']['data'];
          } else {
            this.lastTeamBudget = {};
          }
          this.lastTeamBudget.updated = contractData['teamBudget']['updated'];
          this.teamBudgetSubject.next(this.lastTeamBudget);
        }
        if (contractData?.teamList) {
          this.teamListSubject.next(contractData['teamList']['data']);
        }
        if (contractData?.transactionLedger) {
          let data = contractData['transactionLedger']['data'];
          data['updated'] = contractData['transactionLedger']['updated'];
          this.transactionLedgerSubject.next(data);
        }
        if (contractData?.status) {
          this.tempTransactionStatusSubject.next(contractData['status']);
        }
        if (contractData?.capHolds) {
          this.capHoldsSubject.next(contractData['capHolds']['data']);
        }
        if (contractData?.faBoardPlayers) {
          this.freeAgentSubject.next(contractData['faBoardPlayers']);
        }
        if (contractData?.faBoardTeams) {
          this.faBoardTeamSubject.next(contractData['faBoardTeams']);
        }
        if (contractData?.qualifyingOffers) {
          this.qualifyingOfferSubject.next(contractData['qualifyingOffers']['data']);
        }
        if (contractData?.toggledPicks) {
          this.selectedDraftAssetSubject.next(contractData['toggledPicks']['data']);
        }
        if (contractData?.createdScenarioID) {
          this.scenarioChangeSubject.next(contractData.createdScenarioID);
        }
        if (contractData?.exceptions) {
          this.exceptionsSubject.next(contractData['exceptions']['data']);
        }
        if (contractData?.externalData) {
          this.externalDataSubject.next(contractData['externalData']);
        }
        if (contractData?.focusGroups) {
          this.focusGroupMembershipSubject.next(contractData['focusGroups']);
        }
        if (contractData?.focusGroupGroupings) {
          this.focusGroupEntityGroupingSubject.next(contractData['focusGroupGroupings']);
        }
        if (contractData?.offers) {
          this.offersSubject.next(contractData['offers']['data']);
        }
        if (contractData?.assetList) {
          this.assetListSubject.next(contractData['assetList']);
        }
        if (contractData?.playerInterest) {
          this.playerInterestSubject.next(contractData['playerInterest']['data'])
        }
      }
    });

    contractSocket.onclose = (event) => {
      console.log('Socket is closed. Reconnect will be attempted in 5 second.');
      let $this = this;
      setTimeout(function() {
        $this.connectContractSocket(minDate, maxDate, selectedTransactionFilter, scenarioID);
      }, 5000);
    }

    contractSocket.onerror = (event) => {
      console.log('Socket encountered error');
      contractSocket.close();
    }
  }

  closeContractSocket(attemptReconnect) {
    if (!attemptReconnect && this.contractSocket) {
      this.contractSocket.onclose = (event) => {
        console.log('Socket should be closed for good');
      }
    }
    if (this.contractSocket) {
      this.contractSocket.close();
    }
  }

  sendContractSocketAction(actionData) {
    this.contractSocket.send(JSON.stringify(actionData));
  }

  createCurrentContractSocket(minDate: string, maxDate: string, scenarioID: number) {
    this.currentContractSocket = createThunderWebSocket(this.currentContractSocket, `contract/${scenarioID}/${minDate}/${maxDate}/true`, this.broadcastChannelService);
    return this.currentContractSocket;
  }

  connectCurrentContractSocket(scenarioID?: number) {
    let minDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    let maxDate = moment().format('YYYY-MM-DD');
    scenarioID = scenarioID || 0;
    let contractSocket = this.createCurrentContractSocket(minDate, maxDate, scenarioID);

    contractSocket.messages.pipe(untilDestroyed(this)).subscribe((event) => {
      console.log('Socket sent data');
      if (event.data) {
        const contractData = JSON.parse(event.data);
        if (contractData?.permsError) {
          this.router.navigate([], {
            queryParams: {scenarioID: 'null'},
            queryParamsHandling: 'merge'
          });
          this.selectedScenarioID.next(null);
          this.closeCurrentContractSocket(false);
          this.connectCurrentContractSocket(null);
        }
        if (contractData?.channelName) {
          let payload = {};
          payload[connectionSuccessfulMessage] = {'minDate': minDate, 'maxDate': maxDate, 'selectedTransactionFilter': 'All Transactions', 'scenarioID': scenarioID, 'loadAllTempTransactions': true};

          this.sendCurrentContractSocketAction(payload);
        }
        if (contractData?.teamBudget || contractData?.transactionLedger || contractData?.exceptions) {
          Object.keys(contractData).forEach(datatype => {
            if (datatype == 'transactionLedger') {
              let ledgerData = _.cloneDeep(contractData[datatype]['data']);
              Object.keys(ledgerData).forEach(ledgerType => {
                this.lastCurrentContractData[ledgerType] = ledgerData[ledgerType];
              });
            } else {
              this.lastCurrentContractData[datatype] = contractData[datatype]['data'];
            }
          });
          this.currentContractSubject.next(this.lastCurrentContractData);
        }
      }
    });

    contractSocket.onclose = (event) => {
      console.log('Socket is closed. Reconnect will be attempted in 5 second.');
      let $this = this;
      setTimeout(function() {
        $this.connectCurrentContractSocket(scenarioID);
      }, 5000);
    }

    contractSocket.onerror = (event) => {
      console.log('Socket encountered error');
      contractSocket.close();
    }
  }

  closeCurrentContractSocket(attemptReconnect) {
    if (!attemptReconnect && this.currentContractSocket) {
      this.currentContractSocket.onclose = (event) => {
        console.log('Socket should be closed for good');
      }
    }
    if (this.currentContractSocket) {
      this.currentContractSocket.close();
    }
  }

  sendCurrentContractSocketAction(actionData) {
    this.currentContractSocket.send(JSON.stringify(actionData));
  }

  updateShowFABoard() {
    combineLatest([
      this.selectedScenarioID,
      this.faBoardTeamSubject,
    ]).pipe(map(([scenarioID, faBoardData]) => {
          if (faBoardData) {
            // Check that the current scenario is not Current NBA or in faBoardData
            let showFABoard = !scenarioID;
            if (!showFABoard) {
              showFABoard = Object.keys(FA_BOARD_SCENARIO_TYPES).some(tier =>
                  faBoardData.data.scenarios[tier]?.scenario?.id &&
                  scenarioID === faBoardData.data.scenarios[tier].scenario.id
              );
            }
            return showFABoard;
          }
          return false;
        })
      ).subscribe(this.showFABoard);
  }

  getRelatedOffers() {
      const endpoint = `${this.baseUrl}/contract/offers`;
      return this.get(endpoint).pipe(map(
        data => {
          return data;
        },
        error => {
          return error;
        }
      ));
  }

  getAssetList(date) {
    let params: HttpParams = new HttpParams();

    if (date) {
      params = params.set('date', date);
    }
    const endpoint = `${this.baseUrl}/contract/assetList`;
    return this.get(endpoint, params).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  uploadTradeOffer(offerData) {
    const endpoint = `${this.baseUrl}/contract/offer`;
    return this.post(endpoint, offerData).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  deleteTradeOffer(offerData) {
    const endpoint = `${this.baseUrl}/contract/offer/${offerData.id}`;
    return this.delete(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }
}
