// If these change, must also change in helpers/highlights.py
export const tsaOptions = [
  { text: "Knows How to Play", color: "#FF6961", playerOnly: true },
  { text: "Shot Creation", color: "#BFFF00", playerOnly: true },
  { text: "Plays Hard", color: "#85E3FF", playerOnly: true },
  { text: "Shooting", color: "#AAFFC3", playerOnly: true },
  { text: "Athleticism", color: "#FFCCF9", playerOnly: true },
  { text: "Defensive Impact", color: "#DCB9ED", playerOnly: true },
  { text: "Finishing", color: "#41A85F", playerOnly: true },
  { text: "Size", color: "#D8A076", playerOnly: true },
];
export const intelOptions = [
  { text: "Physical", color: "#FFB347", playerOnly: true },
  { text: "Inner Circle", color: "#87CEFA", playerOnly: true },
];

export const highlightMenuOptions = {
  0: {
    title: 'TSA',
    suboptions: tsaOptions.map((option) => ({
      cmd: `tagDropdown`,
      title: option.text,
      color: option.color,
      playerOnly: option.playerOnly,
    }))
  },
  1: {
    title: 'Intel',
    suboptions: intelOptions.map((option) => ({
      cmd: `tagDropdown`,
      title: option.text,
      color: option.color,
      playerOnly: option.playerOnly,
    }))
  },
};

export const svgRightCaret = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin: 0">
 <path d="M9 18l6-6-6-6" style="fill: none"/>
</svg>`;

export const svgSubRightCaret = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin: 0; width: 20px; height: 20px;">
 <path d="M9 18l6-6-6-6" style="fill: none"/>
</svg>`;

export const svgSubDownCaret = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin: 0; width: 20px; height: 20px;"">
  <path d="M6 9l6 6 6-6" style="fill: none"/>
</svg>`;
