import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, Subject} from "rxjs";
import { highlightMenuOptions, svgRightCaret, svgSubRightCaret, svgSubDownCaret } from 'apps/bild/_shared/post/_constants/post.constants';
import FroalaEditor from 'froala-editor';
import { tsaOptions, intelOptions } from 'apps/bild/_shared/post/_constants/post.constants';

declare let $: any;

@UntilDestroy()
@Injectable()
export class PostHelper {
  constructor(protected router: Router) {
  }

  readonly leftOffset = 34;

  postUpdateSubject: Subject<boolean> = new Subject<boolean>();
  scrollClearSubject: Subject<boolean> = new Subject<boolean>();
  scrollValueSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  positionActionsButtons(thisRef): void {
    const boundingBox = thisRef.postComponent.nativeElement.getBoundingClientRect();
    const position = boundingBox.x + boundingBox.width - this.leftOffset;
    if (thisRef?.postActions && !thisRef.isMobile) {
      thisRef.postActions.nativeElement.style.left = position + 'px';
    }
    if (thisRef?.scrollToTop && !thisRef.isMobile) {
      thisRef.scrollToTop.nativeElement.style.left = position + 'px';
    }
    thisRef.cdr.markForCheck();
  }

  selectedPostListener(thisRef): void {
    thisRef.postList.selectedPost.pipe(untilDestroyed(this)).subscribe((post) => {
      if (thisRef.postList.isHighlighting) {
        // We need to null the post for a short time so that froala works correctly
        thisRef.selectedPost = null;
        setTimeout((_) => {
          thisRef.selectedPost = post;
          if (post) {
            thisRef.cdr.detectChanges();
            if (thisRef.selectedPost) {
              thisRef.editPermissions = thisRef.selectedPost.editPermissions(thisRef.authService);
              thisRef.deletePermissions = thisRef.selectedPost.deletePermissions(thisRef.authService);
            }
            if (thisRef.isMobile && thisRef.contentMiddle?.nativeElement?.scrollTop) {
              thisRef.contentMiddle.nativeElement.scrollTop = 0;
            }
            thisRef.cdr.markForCheck();
          }
        }, 10);
      }
    });
  }

  triggerUpdate(): void {
    this.postUpdateSubject.next(true);
  }

  optimizedImageUrl(imageUrl: string): string {
    let url = `${imageUrl}?`;
    url += 'auto=enhance&fit=crop&crop=faces&mask=corners&h=144&w=144&corner-radius=72&fm=png';
    return url;
  }

  getTagDropdownHtml(curPost: any, globalPost: any): string {
    let postEnts = curPost?.about ?? globalPost?.about;
    let html = '<div class="post-feed__highlight-tag-menu">';
    html += '  <ul class="fr-dropdown-list">';
    for (const [menuKey, menu] of Object.entries(highlightMenuOptions)) {
      html += `
        <li class="menu-header no-froala-cmd has-submenu"
            data-submenu-id="submenu-${menuKey}">
          <span class="menu-title">${menu.title}</span>
          <span class="menu-caret"></span>
          <ul class="submenu"
              id="submenu-${menuKey}"
              style="display: none;">
      `;
      menu.suboptions.forEach((option, index) => {
        let roundCorner = (menuKey === '0' && index === 0);
        html += `
          <li class="menu-suboption">
            <a class="fr-title suboption-link ${roundCorner ? 'rounded' : ''}">
              <span class="menu-color-bubble" style="background-color: ${option.color}"></span> ${option.title}
              <span class="submenu-caret" data-expanded="false"></span>
            </a>
            <ul class="post-ents" style="display: none;">
              ${(option.playerOnly ? postEnts.filter(ent => ent.subtype?.id == 1) : postEnts)
                .map(
                  (element) => 
                  `<li class="fr-command post-ent-item" data-cmd="${option.cmd}" data-param1="${menuKey}-${index}-${element.id}">
                    <img class="entity-img" src="${this.optimizedImageUrl(element.imageUrl)}">
                    <span class="entity-name" data-entity="${element.name}">${element.name}</span>
                  </li>`
                ).join('')}
            </ul>
          </li>`;
      });
      html += `
          </ul>
        </li>
      `;
    }
    html += `
        <li class="menu-suboption">
          <a class="fr-command fr-title remove-link" data-cmd="tagDropdown" data-param1="REMOVE">
            <svg width="12" height="9" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M15,10v8H9v-8H15 M14,4H9.9l-1,1H6v2h12V5h-3L14,4z M17,8H7v10c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V8z"></path>
            </svg>
            Remove
          </a>
        </li>
      </ul>
    </div>`;
    return html;
  }

  handleTagDropdownRefreshOnShow(froalaInstance: any, $dropdown: any, curPost: any, globalPost: any): void {
    function closeAllDropdowns() {
      const $submenuHeaders = $dropdown.find('.menu-header.no-froala-cmd');
      const submenuHeadersArray = $submenuHeaders.toArray();
      submenuHeadersArray.forEach((menu) => {
        const submenuID = menu.getAttribute('data-submenu-id');
        const submenu = document.getElementById(submenuID);
        const otherMenuCaret = menu.querySelector('.menu-caret');
        if (submenu) {
          submenu.style.display = 'none';
          menu.style.backgroundColor = '#ffffff';
          otherMenuCaret.innerHTML = svgRightCaret;
          const $entMenuHeaders = $dropdown.find('li.menu-suboption');
          resetEntityMenus($entMenuHeaders);
        }
      });
      resizeDropdown();
    }

    function resetEntityMenus($entMenuHeaders, $curEntMenuHeader = null) {
      $entMenuHeaders.each(function () {
        const otherSuboption = this;
        if ($curEntMenuHeader && otherSuboption === $curEntMenuHeader) return;
        const otherEntMenu = otherSuboption.querySelector('.post-ents');
        const otherCaret = otherSuboption.querySelector('.submenu-caret');
        const otherSuboptionLink = otherSuboption.querySelector('.suboption-link');
        if (otherEntMenu) {
          otherEntMenu.style.display = 'none';
        }
        if (otherCaret) {
          otherCaret.innerHTML = svgSubRightCaret;
          otherCaret.setAttribute('data-expanded', 'false');
        }
        if (otherSuboptionLink) {
          otherSuboptionLink.style.backgroundColor = '#ffffff';
        }
      });
    }

    function initEntMenu($dropdown) {
      const $entMenuHeaders = $dropdown.find('li.menu-suboption');
      $entMenuHeaders.off('click.flyoutMenu');
      $entMenuHeaders.on('click.flyoutMenu', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const menuSuboption = e.target.closest('.menu-suboption');
        const suboptionLink = e.target.closest('.suboption-link');
        if (!menuSuboption || !suboptionLink) return;
        const entMenu = menuSuboption.querySelector('.post-ents');
        const caret = suboptionLink.querySelector('.submenu-caret');
        if (!entMenu) return;
        resetEntityMenus($entMenuHeaders, menuSuboption);
        entMenu.style.display = (entMenu.style.display === 'none') ? 'block' : 'none';
        suboptionLink.style.backgroundColor = (entMenu.style.display === 'block') ? '#ebebeb' : '#ffffff';
        caret.innerHTML = (entMenu.style.display === 'block') ? svgSubDownCaret : svgSubRightCaret;
        caret.setAttribute('data-expanded', (entMenu.style.display === 'block') ? 'true' : 'false');
        resizeDropdown();
      });
    }

    function resizeDropdown() {
      const $dropdownWrapper = $('.fr-dropdown-wrapper');
      const $menu = $dropdownWrapper.find('.fr-dropdown-content .post-feed__highlight-tag-menu');
      const menuHeightPx = $menu.outerHeight() + 'px';
      const menuWidthPx = $menu.outerWidth() + 'px';
      $dropdownWrapper.css({
        height: menuHeightPx,
        width: menuWidthPx,
        overflow: 'visible'
      });
    }

    function hexToRgb(hex: string) {
      hex = hex.substring(1);
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return `rgb(${r}, ${g}, ${b})`;
    }

    if (curPost?.postID !== globalPost?.postID) {
      curPost = _.cloneDeep(globalPost);
      const updatedHTML = FroalaEditor.COMMANDS['tagDropdown'].html();
      $dropdown.find('.post-feed__highlight-tag-menu').replaceWith($(updatedHTML));
    }

    closeAllDropdowns();
    const $submenuHeaders = $dropdown.find('.menu-header.no-froala-cmd');
    $submenuHeaders.off('click.flyoutMenu');
    $submenuHeaders.on('click.flyoutMenu', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const menuHeader = e.target.closest('li.menu-header.no-froala-cmd');
      const submenuID = menuHeader.getAttribute('data-submenu-id');
      const submenu = document.getElementById(submenuID);
      const menuCaret = menuHeader.querySelector('.menu-caret');
      if (!submenu) return;
      const submenuHeadersArray = $submenuHeaders.toArray();
      submenuHeadersArray.forEach((menu) => {
        const menuID = menu.getAttribute('data-submenu-id');
        const otherMenuCaret = menu.querySelector('.menu-caret');
        if (menuID !== submenuID) {
          const otherSubmenu = document.getElementById(menuID);
          if (otherSubmenu) {
            otherSubmenu.style.display = 'none';
            menu.style.backgroundColor = '#ffffff';
            otherMenuCaret.innerHTML = svgRightCaret;
            const $entMenuHeaders = $dropdown.find('li.menu-suboption');
            resetEntityMenus($entMenuHeaders);
          }
        }
      });
      submenu.style.display = (submenu.style.display === 'none') ? 'block' : 'none';
      menuHeader.style.backgroundColor = (submenu.style.display === 'block') ? '#ebebeb' : '#ffffff';
      menuCaret.innerHTML = (submenu.style.display === 'block') ? '' : svgRightCaret;
      resizeDropdown();
      initEntMenu($dropdown);
    });

    // Remove existing selection indicators
    let bElements = $dropdown[0].getElementsByTagName('b');
    bElements = Array.from(bElements);
    bElements.forEach((b) => {
      b.parentNode.replaceChild(b.firstChild, b);
    });
    let selectedColorBubbles = $dropdown[0].querySelectorAll('.color-bubble-checked');
    selectedColorBubbles = Array.from(selectedColorBubbles);
    selectedColorBubbles.forEach((bubble) => {
      bubble.classList.remove('color-bubble-checked');
    });

    if (
      froalaInstance.selection.element().nodeName === 'SPAN' &&
      froalaInstance.selection.endElement().nodeName === 'SPAN'
    ) {
      // Match all background-color: rgb(x, y, z)
      const regex = /background-color:\s*rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/gi;
      const allSelectionHTML =
        froalaInstance.selection.element().outerHTML +
        froalaInstance.selection.endElement().outerHTML;
      let matches: string[] = allSelectionHTML.match(regex) || [];
      // Extract all rgb(x, y, z)
      matches = matches.map((match) => {
        const rgbMatch = match.match(/rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/);
        return rgbMatch ? rgbMatch[0] : '';
      });
      matches = [...new Set(matches)];

      if (matches.length === 1) {
        // For each rgb found, bold the corresponding menu options
        Object.values(highlightMenuOptions).forEach((menu: any) => {
          menu.suboptions.forEach((option: any, index: number) => {
            if (hexToRgb(option.color) === matches[0]) {
              let colorBubbleElement =
                $dropdown[0]?.firstElementChild.firstElementChild.firstElementChild.children[
                  index
                ]?.firstElementChild.childNodes[1];
              colorBubbleElement?.classList.add('color-bubble-checked');
              let boldElement = document.createElement('b');
              let dropdownTagTextNode =
                $dropdown[0].firstElementChild.firstElementChild.firstElementChild.children[
                  index
                ]?.firstElementChild.childNodes[2];
              boldElement.appendChild(dropdownTagTextNode?.cloneNode(true));
              dropdownTagTextNode.parentNode.replaceChild(boldElement, dropdownTagTextNode);
            }
          });
        });
      }
    }
  }

  formatEntityName(entityName: string): string {
    if (!entityName) return '\u2014';
    const nameParts = entityName.trim().split(/\s+/);
    if (nameParts.length < 2) return entityName;
    const isSuffix = (part: string): boolean => {
      return (
        part.length <= 3 ||
        /^\d+$/.test(part) ||
        part.endsWith('.')
      );
    };
    const hasSuffix = isSuffix(nameParts[nameParts.length - 1]);
    const lastNameStartIndex = 1;
    const lastNameEndIndex = hasSuffix ? nameParts.length - 1 : nameParts.length;
    const lastNameParts = nameParts.slice(lastNameStartIndex, lastNameEndIndex);
    const lastName = lastNameParts.join(' ');
    const suffix = hasSuffix ? ` ${nameParts[nameParts.length - 1]}` : '';
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase() + '.';
    return `${firstNameInitial} ${lastName}${suffix}`;
  }

  getTagCategory(tagText: string): string {
    if (tsaOptions.some(option => option.text === tagText)) {
      return 'TSA';
    }
    if (intelOptions.some(option => option.text === tagText)) {
      return 'Intel';
    }
    return '—';
  }
}
