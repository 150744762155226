import {InjectionToken} from '@angular/core';

import {IAppsConfig} from './apps-config.interface';
import {COMMA, ENTER, TAB} from '@angular/cdk/keycodes';

const currentDate = new Date();

export const APPS_DI_CONFIG: IAppsConfig = {
  CURRENT_SEASON: currentDate.getMonth() >= 7 ? currentDate.getFullYear() : currentDate.getFullYear() - 1,
  METRIC_LIMITS: {
    xEffectiveFieldGoalPct: {ptile10: 0.446312016020386, ptile90: 0.5575099673166515, bufferFactor: 0.1},
    qEffectiveFieldGoalPct: {ptile10: 0.4675788200699542, ptile90: 0.5795510315255011, bufferFactor: 0.1},
    minutes: {ptile10: 63.18999999999998, ptile90: 2313.17266666667, bufferFactor: 0.1},
    freeThrowPct: {ptile10: 0.5809340394800195, ptile90: 0.867081290700227, bufferFactor: 0.1},
    fieldGoalPct: {ptile10: 0.340697396704084, ptile90: 0.55942315426038, bufferFactor: 0.1},
    fieldGoal2Pct: {ptile10: 0.40501638001637963, ptile90: 0.6068108108108111, bufferFactor: 0.1},
    fieldGoal3Pct: {ptile10: 0.12697368421052632, ptile90: 0.4058939873417723, bufferFactor: 0.1},
    effectiveFieldGoalPct: {ptile10: 0.4057239057239055, ptile90: 0.594791905542287, bufferFactor: 0.1},
    freeThrowAttemptPct: {ptile10: 1.263599737833135, ptile90: 7.396641732933315, bufferFactor: 0.1},
    usage: {ptile10: 0.1268990785351824, ptile90: 0.2554590954001076, bufferFactor: 0.1},
    pointsPerAttempt: {ptile10: 0.899074074074074, ptile90: 1.290059249506255, bufferFactor: 0.1},
    turnoverPct: {ptile10: 0.0714285714285714, ptile90: 0.177688054706824, bufferFactor: 0.1},
    assistPct: {ptile10: 0.05078202487220565, ptile90: 0.26054107092795054, bufferFactor: 0.1},
    offensiveReboundPct: {ptile10: 0.011448923425611119, ptile90: 0.10435925954665799, bufferFactor: 0.1},
    defensiveReboundPct: {ptile10: 0.07824658651522473, ptile90: 0.24667146974063422, bufferFactor: 0.1},
    stealPct: {ptile10: 0.006720661454930496, ptile90: 0.0240443078876657, bufferFactor: 0.1},
    blockPct: {ptile10: 0.0, ptile90: 0.039374307534866426, bufferFactor: 0.1},
    gamesPlayed: {ptile10: 7.0, ptile90: 80.0, bufferFactor: 0.1},
    gamesAvailable: {ptile10: 19.0, ptile90: 82.0, bufferFactor: 0.1},
    minutesPerGame: {ptile10: 6.923049019607847, ptile90: 31.79292175925923, bufferFactor: 0.1},
    minutesPerAvailableGame: {ptile10: 2.634428270042193, ptile90: 31.266233568420034, bufferFactor: 0.1},
    pointsPerGame: {ptile10: 2.2472222222222222, ptile90: 17.027160493827168, bufferFactor: 0.1},
    assistsPerGame: {ptile10: 0.3980952380952383, ptile90: 4.1831485587583135, bufferFactor: 0.1},
    offensiveReboundsPerGame: {ptile10: 0.13285714285714262, ptile90: 1.830790960451976, bufferFactor: 0.1},
    defensiveReboundsPerGame: {ptile10: 0.7312638580931267, ptile90: 5.337719633307871, bufferFactor: 0.1},
    totalReboundsPerGame: {ptile10: 1.0, ptile90: 7.1795366795366835, bufferFactor: 0.1},
    ftMadePerGame: {ptile10: 0.21390977443609002, ptile90: 2.9319642857142876, bufferFactor: 0.1},
    freeThrowAttemptsPerGame: {ptile10: 0.34833333333333333, ptile90: 3.7515432098765435, bufferFactor: 0.1},
    blocksPerGame: {ptile10: 0.0, ptile90: 0.8608592826172647, bufferFactor: 0.1},
    turnoversPerGame: {ptile10: 0.263157894736842, ptile90: 2.1636674649332863, bufferFactor: 0.1},
    stealsPerGame: {ptile10: 0.16600000000000034, ptile90: 1.186360779384038, bufferFactor: 0.1},
    personalfoulsPerGame: {ptile10: 0.603103448275862, ptile90: 2.7250000000000045, bufferFactor: 0.1},
  },
  DATE_FORMATS: {
    ISO_8601: 'YYYY-MM-DD',
    SHORT: 'M/D/YY'
  },
  PERSON_TYPE_PLAYER_ID: 1,
  GLG_ID: 2,
  NBA_ID: 1,
  NBA_ENTITY_ID: 41,
  GLG_ENTITY_ID: 44,
  NCAA_ENTITY_ID: 42,
  INTERNATIONAL_ENTITY_ID: 43,
  NCAA_ID: 101,
  INTERNATIONAL_ID: 102,
  CHIP_SEPARATOR_KEY_CODES: [ENTER, COMMA, TAB],
};

export let APPS_CONFIG = new InjectionToken<IAppsConfig>('apps.config');
