import * as moment from "moment";

export enum SalaryLevel {
    CAP = 'Cap',
    TAX = 'Tax',
    APRON = 'Apron',
    MTS = 'MTS',
    SUMMARY = 'Summary',
}

export enum ContractType {
    CAP_HOLD = 'HOLD',
    DRAFT_PICK = 'DRFPK',
    ETO = 'ETO',
    GTD = 'GTD',
    MTS = 'MTS',
    NG = 'NG',
    PG = 'PG',
    PG_NG = 'PG/NG',
    PLAYER_OPTION = 'PO',
    QO = 'QO',
    ROOKIE_SCALE = 'RKSC',
    SEQUENCED_TRANSACTION = 'SEQ',
    SUMMER = 'SUMM',
    TEAM_OPTION = 'TO',
    TWO_WAY = '2-WAY',
    WAIVED_SALARY = 'DEAD',
}

export enum DraftRound {
    FIRST_ROUND = 'firstRound',
    SECOND_ROUND = 'secondRound',
}

export enum DepthChartTier {
    STARTER = 'starter',
    ROTATION = 'rotation',
    LIMITED = 'limited pt',
}

export enum FreeAgentBoardTier {
    LIKELY = 'likely',
    UNLIKELY = 'unlikely',
    USED = 'used',
}

export enum FreeAgentDesignation {
    BIRD = 'QUAL',
    EARLY_BIRD = 'ERQL',
    NON_BIRD = 'NONQL',
}

export enum FreeAgentStatus {
    RFA = 'RFA',
    UFA = 'UFA',
}

export enum InjuryStatus {
    OFS = 'ofs',
    OUT = 'out',
    GTD = 'gtd',
    AVAIL = 'avail',
}

export enum OptionDecision {
    DECLINE = 'Decline Option',
    DELETE = 'Delete Option',
    EXERCISE = 'Exercise Option',
}

export enum TradeNoteTabs {
    INTEREST = 'Interest',
    AVAILABILITY = 'Availability',
    TEAM_NEEDS = 'Needs',
    OFFERS = 'Offers',
}

export enum TransactionType {
    SIGNING = 'SIGN',
    OPTION_DECISION = 'OPT',
    RENEGOTIATION = 'RENOG',
    TRADE = 'TRADE',
    WAIVE = 'WAIVE',
    DRAFT = 'DRAFT',
}

export enum TempTransactionAction {
    CREATE = 1,
    EDIT = 2,
    DELETE = 3,
    CREATE_SCENARIO = 4,
    EDIT_SCENARIO = 5,
    DELETE_SCENARIO = 6,
    ENABLE_CAP_HOLDS = 7,
    DISABLE_CAP_HOLDS = 8,
    UPDATE_FREE_AGENT_SOURCE = 9,
    UPDATE_FA_BOARD_SCENARIO = 10,
    UPDATE_TEMP_EXCEPTION = 11,
    ENABLE_QO = 12,
    DISABLED_QO = 13,
    COPY_SCENARIO = 14,
    ARCHIVE_SCENARIO = 15,
    ACTIVATE_SCENARIO = 16,
    TOGGLE_ON_PICK = 17,
    TOGGLE_OFF_PICK = 18,
    GET_TRADE_ASSET_LIST = 19,
    UPLOAD_OFFER = 20,
    DELETE_OFFER = 21,
}

export enum TempTransactionStatus {
    QUEUED = 'queued',
    PROCESSING = 'processing',
    READY = 'ready',
    READY_WITH_PROCESSING = 'readyWithProcessing',
}

export enum SigningContractType {
    REGULAR = 'REGCT',
    ROOKIE = 'RKSC',
    ROOKIE_2ND_ROUND = 'RND2E', // Made up contract type to apply 2nd round exception
    ROOKIE_EXT = 'RSEX',
    ROS = 'ROS',
    TEN_DAY_1 = '10D1',
    TEN_DAY_2 = '10D2',
    TWO_WAY = '2WCT',
    VET_EXT = 'VETEX',
}

export const PCMS_PLAYER_BASE_URL = 'https://pcms.nba.com/#/pcms/player/';
export const PCMS_TEAM_BASE_URL = 'https://pcms.nba.com/#/pcms/teambudget/';
export const TRADED_PLAYER_EXCEPTION = 'TREXC';
export const PRIOR_TAX_SEASONS_DISPLAYED = 4;
export const TEAM_SALARY_YEARS_DISPLAYED = 6;
export const SECOND_APRON_LOCKED_DRAFT_YEAR_OFFSET = 8;
export const SECOND_APRON_LOCKED_DRAFT_COUNT_THRESHOLD = 2;
export const SECOND_APRON_LOCKED_DRAFT_START_YEAR = 2024;
export const PLACEHOLDER_PLAYER_ID = 99999999;

export const CONTRACT_TYPE_TO_CLASS_NAME = {
    [ContractType.CAP_HOLD]: 'cap-hold',
    [ContractType.DRAFT_PICK]: 'draft-pick',
    [ContractType.ETO]: 'eto',
    [ContractType.NG]: 'non-fully-guaranteed',
    [ContractType.PG]: 'non-fully-guaranteed',
    [ContractType.PG_NG]: 'non-fully-guaranteed',
    [ContractType.PLAYER_OPTION]: 'player-option',
    [ContractType.QO]: 'qo',
    [ContractType.SUMMER]: 'summer',
    [ContractType.TEAM_OPTION]: 'team-option',
    [ContractType.TWO_WAY]: 'two-way',
};

export const FA_DESIGNATION_DISPLAY = {
    [FreeAgentDesignation.BIRD]: 'Bird',
    [FreeAgentDesignation.EARLY_BIRD]: 'Early-Bird',
    [FreeAgentDesignation.NON_BIRD]: 'Non Bird',
};

export const SALARY_LEVEL_TO_DISPLAY_NAME = {
    [SalaryLevel.MTS]: 'Salary Floor',
    [SalaryLevel.CAP]: 'Salary Cap',
    [SalaryLevel.TAX]: 'Luxury Tax',
    [SalaryLevel.APRON]: 'First Apron',
};

export const SALARY_LEVEL_TO_FIELD_NAME = {
    [SalaryLevel.MTS]: 'mtsamount',
    [SalaryLevel.CAP]: 'capamount',
    [SalaryLevel.TAX]: 'taxamount',
    [SalaryLevel.APRON]: 'apronamount',
};

export const TRANSACTION_TYPE_DISPLAY_NAME = {
    [TransactionType.SIGNING]: 'Signing',
    [TransactionType.RENEGOTIATION]: 'Renegotiation',
    [TransactionType.TRADE]: 'Trade',
    [TransactionType.WAIVE]: 'Waive Player',
    [TransactionType.OPTION_DECISION]: 'Option Decision',
};

export const SIGNED_CONTRACT_TYPE_TO_DISPLAY_NAME = {
    [SigningContractType.TEN_DAY_1]: '10 Day',
    [SigningContractType.TWO_WAY]: '2-Way',
    [SigningContractType.ROOKIE_2ND_ROUND]: '2nd Round Exception',
    [SigningContractType.REGULAR]: 'Regular Contract',
    [SigningContractType.ROOKIE_EXT]: 'Rookie Extension',
    [SigningContractType.ROOKIE]: 'Rookie Scale',
    [SigningContractType.ROS]: 'ROS',
    [SigningContractType.VET_EXT]: 'Vet Extension',
};

export const PCMS_TRANSACTION_TYPE_MAP = {
    'SIGN': TransactionType.SIGNING,
    'TRADE': TransactionType.TRADE,
    '2WTRD': TransactionType.TRADE,
    'TRADL': TransactionType.TRADE,
    'WAIVE': TransactionType.WAIVE,
    '2WWAV': TransactionType.WAIVE,
    'OPT': TransactionType.OPTION_DECISION,
    'CTAMN': TransactionType.RENEGOTIATION,
};

export const BUYOUT_TRANSACTION = 'DIV';
export const CONTRACT_OPTIONS = ['ETO', 'PO', 'TO'];
export const SETOFF_TRANSACTION = 'ADJSO';
export const WAIVER_STRETCH_TRANSACTION = 'WVSTR';
export const RELEASE_TEN_DAY_TRANSACTION = 'CTR10';
export const TWO_WAY_WAIVE_TRANSACTION = '2WWAV';
export const WAIVER_CLEAR_TRANSACTIONS = new Set(['WVCLR', '2WWVC']);
export const WAIVER_RELATED_TRANSACTIONS = new Set([...['WAIVE', TWO_WAY_WAIVE_TRANSACTION, SETOFF_TRANSACTION, BUYOUT_TRANSACTION, WAIVER_STRETCH_TRANSACTION], ...WAIVER_CLEAR_TRANSACTIONS]);

export const NO_OPTION = 'NONE';

export const ROOM_SIGNING = 'ROM';
export const SECOND_ROUND_EXCEPTION = '2RDPE';

export const FULLY_PROTECTED_SALARY = 'FULL';

export const OPTION_DECISION_MAP = {
    'ETO': {
        [OptionDecision.DELETE]: 'ETODE',
        [OptionDecision.EXERCISE]: 'ETOEX',
    },
    'PO': {
        [OptionDecision.DECLINE]: 'POD',
        [OptionDecision.EXERCISE]: 'POE',
    },
    'TO': {
        [OptionDecision.DECLINE]: 'TOD',
        [OptionDecision.EXERCISE]: 'TOE',
    }
};

export const OPTION_PCMS_MAP = {
    'ETO': 'ETO',
    'PO': 'PLYR',
    'TO': 'TEAM',
};

export const TRANSACTION_TO_DECISION_MAP = {
    'ETODE': OptionDecision.DELETE,
    'ETOEX': OptionDecision.EXERCISE,
    'POD': OptionDecision.DECLINE,
    'POE': OptionDecision.EXERCISE,
    'TOD': OptionDecision.DECLINE,
    'TOE': OptionDecision.EXERCISE,
};

export const TRANSACTION_TO_OPTION_TYPE_MAP = {
    'ETODE': 'ETO',
    'ETOEX': 'ETO',
    'POD': 'PO',
    'POE': 'PO',
    'TOD': 'TO',
    'TOE': 'TO',
};

export const PCMS_SUBJECT_APRON_REASONS = [
    {'subjecttoapronreasonlk': 'CASH', 'description': 'Cash Trade'},
    {'subjecttoapronreasonlk': '1I20', 'description': 'One Incoming, Two Outgoing'},
    {'subjecttoapronreasonlk': 'SAT', 'description': 'Sign and Trade'},
    {'subjecttoapronreasonlk': 'SM110', 'description': 'Simultaneous +110%'},
    {'subjecttoapronreasonlk': 'SM125', 'description': 'Simultaneous +125%'},
    {'subjecttoapronreasonlk': 'SM200', 'description': 'Simultaneous +200%'},
    {'subjecttoapronreasonlk': 'SMTPE', 'description': 'Simultaneous +TPE Allowance'},
    {'subjecttoapronreasonlk': 'TPE1', 'description': 'Used Prior Year TPE'},
    {'subjecttoapronreasonlk': 'TPE2', 'description': 'Used TPE From Sign-and-Trade'},
    {'subjecttoapronreasonlk': 'UBA', 'description': 'Used Bi-Annual'},
    {'subjecttoapronreasonlk': 'CMLE', 'description': 'Used Convertible Mid-Level'},
    {'subjecttoapronreasonlk': 'UNTML', 'description': 'Used Non-Taxpayer Mid-Level'},
    {'subjecttoapronreasonlk': 'TMLE', 'description': 'Used Taxpayer Mid-Level'},
    {'subjecttoapronreasonlk': 'SNTML', 'description': 'Waived >= NTMLE'},
];

export enum HiddenContractColumns {
    GTD_CAP_AMOUNT = 'guaranteed',
    NFG_CAP_AMOUNT = 'nonFullyGuaranteed',
    TO_CAP_AMOUNT = 'teamOptions',
    PO_CAP_AMOUNT = 'playerOptions',
    ETO_CAP_AMOUNT = 'earlyTerminationOptions',
    SEQUENCED_CAP_AMOUNT = 'delayTransactionSalary',
    WAIVED_SALARY_AMOUNT = 'waivedSalary',
};

export const HIDDEN_COLUMN_TO_CONTRACT_TYPE = {
    [HiddenContractColumns.GTD_CAP_AMOUNT]: ContractType.GTD,
    [HiddenContractColumns.NFG_CAP_AMOUNT]: ContractType.PG_NG,
    [HiddenContractColumns.TO_CAP_AMOUNT]: ContractType.TEAM_OPTION,
    [HiddenContractColumns.PO_CAP_AMOUNT]: ContractType.PLAYER_OPTION,
    [HiddenContractColumns.ETO_CAP_AMOUNT]: ContractType.ETO,
    [HiddenContractColumns.SEQUENCED_CAP_AMOUNT]: ContractType.SEQUENCED_TRANSACTION,
    [HiddenContractColumns.WAIVED_SALARY_AMOUNT]: ContractType.WAIVED_SALARY,
};

export const FA_BOARD_SCENARIO_TYPES = {
    'high': 'Max Cap Room Scenario',
    'bulls': 'Bullseye Cap Room Scenario',
    'low': 'Low Cap Room Scenario',
};

export const NON_TAX_MLE = 'NTMDL';
export const TAX_MLE = 'TMDLE';
export const CONV_NON_TAX_MLE = 'CNTMD';

export const NON_PLAYER_EXCEPTION_TYPES = [
    {'exceptiontypelk': 'BIEXC', 'display': 'BAE', 'systemValueKey': 'bae'},
    {'exceptiontypelk': 'RMEXC', 'display': 'RMLE', 'systemValueKey': 'rmle'},
    {'exceptiontypelk': NON_TAX_MLE, 'display': 'NTMLE', 'systemValueKey': 'ntmle'},
    {'exceptiontypelk': CONV_NON_TAX_MLE, 'display': 'Conv. NTMLE'},
    {'exceptiontypelk': TAX_MLE, 'display': 'TPMLE', 'systemValueKey': 'tpmle'},
];

export const CONTRACT_TO_HIDDEN_COLUMN_TYPE = {
    [ContractType.GTD]: HiddenContractColumns.GTD_CAP_AMOUNT,
    [ContractType.PG_NG]: HiddenContractColumns.NFG_CAP_AMOUNT,
    [ContractType.TEAM_OPTION]: HiddenContractColumns.TO_CAP_AMOUNT,
    [ContractType.PLAYER_OPTION]: HiddenContractColumns.PO_CAP_AMOUNT,
    [ContractType.ETO]: HiddenContractColumns.ETO_CAP_AMOUNT,
    [ContractType.SEQUENCED_TRANSACTION]: HiddenContractColumns.SEQUENCED_CAP_AMOUNT,
    [ContractType.WAIVED_SALARY]: HiddenContractColumns.WAIVED_SALARY_AMOUNT,
};

export const SALARY_FIELDS = ['capamount', 'protectedCapamount', 'taxamount', 'apronamount'];
export const TV_DEAL_END_YEAR = 2024;
export const CBA_OPT_OUT_YEAR = 2028;
export const MTS_START_DATE = moment([2024, 4, 19]);

export enum ActiveContractColumnCategories {
    GENERAL = 'General',
    METRICS = 'Metrics',
}

export enum ActiveContractMetricsColumnCategoryGroups {
    MEASURES = 'Measures',
    BOX_SCORE = 'Box Score',
    SHOOTING = 'Shooting',
    MODELED_SHOOTING = 'Modeled Shooting',
    PLAYING_TIME = 'Playing Time',
    GENERAL = 'General',
    LOCS = 'LOCS',
    CONTRACT = 'Contract',
}

// This should be in order such that ALL the General columns appear before any Metric column
export enum ActiveContractMetricsColumnOptionIDs {
    BADGES_ID = 1,
    TEAM_ID = 2,
    AGENT_ID = 3,
    AGE_ID = 4,
    POSITION_ID = 5,
    POSITION_GROUP_ID = 6,
    CONTRACT_TYPE_ID = 7,
    CONTRACT_YEAR_1_ID = 8,
    CONTRACT_YEAR_2_ID = 9,
    CONTRACT_YEAR_3_ID = 10,
    CONTRACT_YEAR_4_ID = 11,
    CONTRACT_YEAR_5_ID = 12,
    CONTRACT_YEAR_6_ID = 13,
    TOTAL_CONTRACT_ID = 14,
    YOS_ID = 15,
    FA_STATUS_ID = 16,
    XSALARY_ID = 17,
    DELTA_SALARY_ID = 18,
    LOC_NOW = 19,
    LOC_LOW = 20,
    LOC_BULL = 21,
    LOC_HIGH = 22,
    MSCORE_ID = 23,
    MPAG_ID = 24,
    SA_ID = 25,
    POSS_ID = 26,
    PPA_ID = 27,
    EFG_ID = 28,
    XEFG_ID = 29,
    QEFG_ID = 30,
    USG_ID = 31,
    FTA_100_ID = 32,
    AST_RATE_ID = 33,
    TO_RATE_ID = 34,
    ORB_RATE_ID = 35,
    DRB_RATE_ID = 36,
    STL_RATE_ID = 37,
    BLK_RATE_ID = 38,
    PF_RATE_ID = 39,
    MPG_ID = 40,
    PPG_ID = 41,
    FG_PERCENTAGE_ID = 42,
    TWO_POINTERS_MADE_ID = 43,
    TWO_POINTERS_ATTEMPTED_ID = 44,
    TWO_POINTERS_PERCENTAGE_ID = 45,
    THREE_POINTERS_MADE_ID = 46,
    THREE_POINTERS_ATTEMPTED_ID = 47,
    THREE_POINTERS_PERCENTAGE_ID = 48,
    FREE_THROWS_MADE_ID = 49,
    FREE_THROWS_ATTEMPTED_ID = 50,
    FREE_THROWS_PERCENTAGE_ID = 51,
    DRB_ID = 52,
    ORB_ID = 53,
    TRB_ID = 54,
    AST_ID = 55,
    STL_ID = 56,
    TO_ID = 57,
    BLK_ID = 58,
    PF_ID = 59,
    SHOOTING_ALL_ZONES_ID = 60,
    SHOOTING_DP_ID = 61,
    SHOOTING_THREES_ID = 62,
    SHOOTING_NR2_ID = 63,
    SHOOTING_C3_ID = 64,
    SHOOTING_NC3_ID = 65,
    SHOOTING_BLK_ID = 66,
    SHOOTING_HP_ID = 67,
    SHOOTING_NP2_ID = 68,
    SHOOTING_GAME_STATE_ID = 69,
    MODELED_SHOOTING_ALL_ZONES_ID = 70,
    MODELED_SHOOTING_DP_ID = 71,
    MODELED_SHOOTING_THREES_ID = 72,
    MODELED_SHOOTING_MR2_ID = 73,
    MODELED_SHOOTING_L2_ID = 74,
    MODELED_SHOOTING_FT_ID = 75,
    PLAYING_TIME_POSITION_ID = 76,
    PLAYING_TIME_DEFENSIVE_MATCHUP_ID = 77,
    PLAYING_TIME_ROTATIONS_ID = 78,
    PLAYING_TIME_GAME_MINUTES_ID = 79,
    METRICS_MEASURES_SPACING_ID = 80,
    METRICS_BOX_SCORE_SPACING_ID = 81,
    METRICS_SHOOTING_SPACING_ID = 82,
    METRICS_MODELED_SHOOTING_SPACING_ID = 82,
}

// This follows the ordering, such that after the first Metric column, there should be NO non-General columns after it
export const firstMetricColumn = ActiveContractMetricsColumnOptionIDs.MSCORE_ID;

export const ActiveContractMetricsCustomMinFormattingColumns = [
    ActiveContractMetricsColumnOptionIDs.MSCORE_ID,
    ActiveContractMetricsColumnOptionIDs.PPA_ID,
    ActiveContractMetricsColumnOptionIDs.EFG_ID,
    ActiveContractMetricsColumnOptionIDs.XEFG_ID,
    ActiveContractMetricsColumnOptionIDs.QEFG_ID,
    ActiveContractMetricsColumnOptionIDs.USG_ID,
    ActiveContractMetricsColumnOptionIDs.FTA_100_ID,
    ActiveContractMetricsColumnOptionIDs.AST_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.TO_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.ORB_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.DRB_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.STL_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.BLK_RATE_ID,




];

export const ActiveContractMetricsCustomMaxFormattingColumns = [
    ActiveContractMetricsColumnOptionIDs.MSCORE_ID,
    ActiveContractMetricsColumnOptionIDs.MPAG_ID,
    ActiveContractMetricsColumnOptionIDs.SA_ID,
    ActiveContractMetricsColumnOptionIDs.POSS_ID,
    ActiveContractMetricsColumnOptionIDs.PPA_ID,
    ActiveContractMetricsColumnOptionIDs.EFG_ID,
    ActiveContractMetricsColumnOptionIDs.XEFG_ID,
    ActiveContractMetricsColumnOptionIDs.QEFG_ID,
    ActiveContractMetricsColumnOptionIDs.USG_ID,
    ActiveContractMetricsColumnOptionIDs.FTA_100_ID,
    ActiveContractMetricsColumnOptionIDs.AST_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.TO_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.ORB_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.DRB_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.STL_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.BLK_RATE_ID,

]

export const ActiveContractMetricsColumnOptionIDToField = {
    [ActiveContractMetricsColumnOptionIDs.MSCORE_ID]: 'metrics.measures.mScore',
    [ActiveContractMetricsColumnOptionIDs.MPAG_ID]: 'metrics.measures.minutesPerAvailableGame',
    [ActiveContractMetricsColumnOptionIDs.SA_ID]: 'metrics.measures.shotAttempts',
    [ActiveContractMetricsColumnOptionIDs.POSS_ID]: 'metrics.measures.possessions',
    [ActiveContractMetricsColumnOptionIDs.PPA_ID]: 'metrics.measures.pointsPerAttempt',
    [ActiveContractMetricsColumnOptionIDs.EFG_ID]: 'metrics.measures.effectiveFieldGoalPct',
    [ActiveContractMetricsColumnOptionIDs.XEFG_ID]: 'metrics.measures.xEffectiveFieldGoalPct',
    [ActiveContractMetricsColumnOptionIDs.QEFG_ID]: 'metrics.measures.qEffectiveFieldGoalPct',
    [ActiveContractMetricsColumnOptionIDs.USG_ID]: 'metrics.measures.usage',
    [ActiveContractMetricsColumnOptionIDs.FTA_100_ID]: 'metrics.measures.freeThrowAttemptPct',
    [ActiveContractMetricsColumnOptionIDs.AST_RATE_ID]: 'metrics.measures.assistPct',
    [ActiveContractMetricsColumnOptionIDs.TO_RATE_ID]: 'metrics.measures.turnoverPct',
    [ActiveContractMetricsColumnOptionIDs.ORB_RATE_ID]: 'metrics.measures.offensiveReboundPct',
    [ActiveContractMetricsColumnOptionIDs.DRB_RATE_ID]: 'metrics.measures.defensiveReboundPct',
    [ActiveContractMetricsColumnOptionIDs.STL_RATE_ID]: 'metrics.measures.stealPct',
    [ActiveContractMetricsColumnOptionIDs.BLK_RATE_ID]: 'metrics.measures.blockPct',
    [ActiveContractMetricsColumnOptionIDs.PF_RATE_ID]: 'metrics.measures.foulRate',
    [ActiveContractMetricsColumnOptionIDs.MPG_ID]: 'metrics.measures.minutes$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.PPG_ID]: 'metrics.measures.points$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.FG_PERCENTAGE_ID]: 'metrics.measures.fieldGoalPct',
    [ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_MADE_ID]: 'metrics.measures.fg2Made$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_ATTEMPTED_ID]: 'metrics.measures.fg2Attempted$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_PERCENTAGE_ID]: 'metrics.measures.fieldGoal2Pct',
    [ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_MADE_ID]: 'metrics.measures.fg3Made$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_ATTEMPTED_ID]: 'metrics.measures.fg3Attempted$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_PERCENTAGE_ID]: 'metrics.measures.fieldGoal3Pct',
    [ActiveContractMetricsColumnOptionIDs.FREE_THROWS_MADE_ID]: 'metrics.measures.ftMade$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.FREE_THROWS_ATTEMPTED_ID]: 'metrics.measures.ftAttempted$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.FREE_THROWS_PERCENTAGE_ID]: 'metrics.measures.freeThrowPct',
    [ActiveContractMetricsColumnOptionIDs.DRB_ID]: '$selectedBoxScorePerGameDefensiveReboundAttribute$',
    [ActiveContractMetricsColumnOptionIDs.ORB_ID]: '$selectedBoxScorePerGameOffensiveReboundAttribute$',
    [ActiveContractMetricsColumnOptionIDs.TRB_ID]: '$selectedBoxScorePerGameTotalReboundAttribute$',
    [ActiveContractMetricsColumnOptionIDs.AST_ID]: 'metrics.measures.assists$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.STL_ID]: 'metrics.measures.assists$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.TO_ID]: 'metrics.measures.turnovers$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.BLK_ID]: 'metrics.measures.blocks$selectedBoxScorePerGame$',
    [ActiveContractMetricsColumnOptionIDs.PF_ID]: 'metrics.measures.personalFouls$selectedBoxScorePerGame$',
};

export const ActiveContractMetricsGameTypeOptions = [
    {id: 1, name: 'Preseason'},
    {id: 2, name: 'Regular Season'},
    {id: 3, name: 'All-Star'},
    {id: 4, name: 'Postseason'},
    {id: 5, name: 'Play-In'},
    {id: 6, name: 'In-Season Tournament Championship'},
  ];


export enum ActiveContractMetricsColumnOptionLabels {
    BADGES_LABEL = 'Badges',
    TEAM_LABEL = 'Team',
    AGENT_LABEL = 'Agent',
    AGE_LABEL = 'Age',
    POSITION_LABEL = 'Position',
    POSITION_GROUP_LABEL = 'Position Group',
    CONTRACT_TYPE_LABEL = 'Contract Type',
    CONTRACT_YEAR_1_LABEL = 'Contract Year 1',
    CONTRACT_YEAR_2_LABEL = 'Contract Year 2',
    CONTRACT_YEAR_3_LABEL = 'Contract Year 3',
    CONTRACT_YEAR_4_LABEL = 'Contract Year 4',
    CONTRACT_YEAR_5_LABEL = 'Contract Year 5',
    CONTRACT_YEAR_6_LABEL = 'Contract Year 6',
    TOTAL_CONTRACT_LABEL = 'Total Contract',
    YOS_LABEL = 'Years of Service',
    FA_STATUS_LABEL = 'Free Agent Status',
    XSALARY_LABEL = 'XSalary',
    DELTA_SALARY_LABEL = 'Delta Salary',
    LOC_NOW_LABEL = 'Now LOC',
    LOC_LOW_LABEL = 'Low LOC',
    LOC_BULL_LABEL = 'Bullseye LOC',
    LOC_HIGH_LABEL = 'High LOC',
    MSCORE_LABEL = 'M-Score',
    MPAG_LABEL = 'MPAG',
    SA_LABEL = 'SA',
    POSS_LABEL = 'POSS',
    PPA_LABEL = 'PPA',
    EFG_LABEL = 'EFG',
    XEFG_LABEL = 'XEFG',
    QEFG_LABEL = 'QEFG',
    USG_LABEL = 'USG',
    FTA_100_LABEL = 'FTA/100',
    AST_RATE_LABEL = 'AST%',
    TO_RATE_LABEL = 'TO%',
    ORB_RATE_LABEL = 'ORB%',
    DRB_RATE_LABEL = 'DRB%',
    STL_RATE_LABEL = 'STL%',
    BLK_RATE_LABEL = 'BLK%',
    PF_RATE_LABEL = 'PF%',
    MPG_LABEL = 'MPG',
    PPG_LABEL = 'PPG',
    FG_PERCENTAGE_LABEL = 'FG%',
    TWO_POINTERS_MADE_LABEL = '2PM',
    TWO_POINTERS_ATTEMPTED_LABEL = '2PA',
    TWO_POINTERS_PERCENTAGE_LABEL = '2P%',
    THREE_POINTERS_MADE_LABEL = '3PM',
    THREE_POINTERS_ATTEMPTED_LABEL = '3PA',
    THREE_POINTERS_PERCENTAGE_LABEL = '3P%',
    FREE_THROWS_MADE_LABEL = 'FTM',
    FREE_THROWS_ATTEMPTED_LABEL = 'FTA',
    FREE_THROWS_PERCENTAGE_LABEL = 'FT%',
    DRB_LABEL = 'DRB',
    ORB_LABEL = 'ORB',
    TRB_LABEL = 'TRB',
    AST_LABEL = 'AST',
    STL_LABEL = 'STL',
    TO_LABEL = 'TO',
    BLK_LABEL = 'BLK',
    PF_LABEL = 'PF',
    SHOOTING_ALL_ZONES_LABEL = 'All Zones',
    SHOOTING_DP_LABEL = 'DP',
    SHOOTING_THREES_LABEL = '3S',
    SHOOTING_NR2_LABEL = 'NR2S',
    SHOOTING_C3_LABEL = 'C3',
    SHOOTING_NC3_LABEL = 'NC3',
    SHOOTING_BLK_LABEL = 'BLK',
    SHOOTING_HP_LABEL = 'HP',
    SHOOTING_NP2_LABEL = 'NP2',
    SHOOTING_GAME_STATE_LABEL = 'Game State',
    MODELED_SHOOTING_ALL_ZONES_LABEL = 'All Zones',
    MODELED_SHOOTING_DP_LABEL = 'DP',
    MODELED_SHOOTING_THREES_LABEL = '3S',
    MODELED_SHOOTING_MR2_LABEL = 'MR2S',
    MODELED_SHOOTING_L2_LABEL = 'L2S',
    MODELED_SHOOTING_FT_LABEL = 'FT',
    PLAYING_TIME_POSITION_LABEL = 'Position',
    PLAYING_TIME_DEFENSIVE_MATCHUP_LABEL = 'Defensive Matchup',
    PLAYING_TIME_ROTATIONS_LABEL = 'Rotations',
    PLAYING_TIME_GAME_MINUTES_LABEL = 'Game Minutes',
}

export const ACTIVE_CONTRACT_METRICS_CATEGORIES_FIELD_IDS = [
    {
      category: 'measures',
      fieldIDs: [
        ActiveContractMetricsColumnOptionIDs.MSCORE_ID,
        ActiveContractMetricsColumnOptionIDs.MPAG_ID,
        ActiveContractMetricsColumnOptionIDs.SA_ID,
        ActiveContractMetricsColumnOptionIDs.POSS_ID,
        ActiveContractMetricsColumnOptionIDs.PPA_ID,
        ActiveContractMetricsColumnOptionIDs.EFG_ID,
        ActiveContractMetricsColumnOptionIDs.XEFG_ID,
        ActiveContractMetricsColumnOptionIDs.QEFG_ID,
        ActiveContractMetricsColumnOptionIDs.USG_ID,
        ActiveContractMetricsColumnOptionIDs.FTA_100_ID,
        ActiveContractMetricsColumnOptionIDs.AST_RATE_ID,
        ActiveContractMetricsColumnOptionIDs.TO_RATE_ID,
        ActiveContractMetricsColumnOptionIDs.ORB_RATE_ID,
        ActiveContractMetricsColumnOptionIDs.DRB_RATE_ID,
        ActiveContractMetricsColumnOptionIDs.STL_RATE_ID,
        ActiveContractMetricsColumnOptionIDs.BLK_RATE_ID,
        ActiveContractMetricsColumnOptionIDs.PF_RATE_ID,
        ActiveContractMetricsColumnOptionIDs.MPG_ID,
        ActiveContractMetricsColumnOptionIDs.PPG_ID,
        ActiveContractMetricsColumnOptionIDs.FG_PERCENTAGE_ID,
        ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_MADE_ID,
        ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_ATTEMPTED_ID,
        ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_PERCENTAGE_ID,
        ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_MADE_ID,
        ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_ATTEMPTED_ID,
        ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_PERCENTAGE_ID,
        ActiveContractMetricsColumnOptionIDs.FREE_THROWS_MADE_ID,
        ActiveContractMetricsColumnOptionIDs.FREE_THROWS_ATTEMPTED_ID,
        ActiveContractMetricsColumnOptionIDs.FREE_THROWS_PERCENTAGE_ID,
        ActiveContractMetricsColumnOptionIDs.DRB_ID,
        ActiveContractMetricsColumnOptionIDs.ORB_ID,
        ActiveContractMetricsColumnOptionIDs.TRB_ID,
        ActiveContractMetricsColumnOptionIDs.AST_ID,
        ActiveContractMetricsColumnOptionIDs.STL_ID,
        ActiveContractMetricsColumnOptionIDs.TO_ID,
        ActiveContractMetricsColumnOptionIDs.BLK_ID,
        ActiveContractMetricsColumnOptionIDs.PF_ID
      ]
    },
    {
      category: 'shots',
      fieldIDs: [
        ActiveContractMetricsColumnOptionIDs.SHOOTING_ALL_ZONES_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_DP_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_THREES_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_NR2_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_C3_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_NC3_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_BLK_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_HP_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_NP2_ID,
        ActiveContractMetricsColumnOptionIDs.SHOOTING_GAME_STATE_ID
      ]
    },
    {
      category: 'modeledShooting',
      fieldIDs: [
        ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_ALL_ZONES_ID,
        ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_DP_ID,
        ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_THREES_ID,
        ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_MR2_ID,
        ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_L2_ID,
        ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_FT_ID
      ]
    },
    {
      category: 'playingTime',
      fieldIDs: [
        ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_POSITION_ID,
        ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_DEFENSIVE_MATCHUP_ID
      ]
    },
    {
      category: 'gameMinutes',
      fieldIDs: [
        ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_GAME_MINUTES_ID
      ]
    },
    {
      category: 'rotations',
      fieldIDs: [
        ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_ROTATIONS_ID
      ]
    },
  ];

export const METRICS_MEASURES_COLUMNS = [ActiveContractMetricsColumnOptionIDs.MSCORE_ID, ActiveContractMetricsColumnOptionIDs.MPAG_ID,
    ActiveContractMetricsColumnOptionIDs.SA_ID, ActiveContractMetricsColumnOptionIDs.POSS_ID, ActiveContractMetricsColumnOptionIDs.PPA_ID,
    ActiveContractMetricsColumnOptionIDs.EFG_ID, ActiveContractMetricsColumnOptionIDs.XEFG_ID, ActiveContractMetricsColumnOptionIDs.QEFG_ID,
    ActiveContractMetricsColumnOptionIDs.USG_ID, ActiveContractMetricsColumnOptionIDs.FTA_100_ID, ActiveContractMetricsColumnOptionIDs.AST_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.TO_RATE_ID, ActiveContractMetricsColumnOptionIDs.ORB_RATE_ID, ActiveContractMetricsColumnOptionIDs.DRB_RATE_ID,
    ActiveContractMetricsColumnOptionIDs.STL_RATE_ID, ActiveContractMetricsColumnOptionIDs.BLK_RATE_ID, ActiveContractMetricsColumnOptionIDs.PF_RATE_ID];

export const METRICS_BOX_SCORE_COLUMNS = [ActiveContractMetricsColumnOptionIDs.MPG_ID, ActiveContractMetricsColumnOptionIDs.PPG_ID,
    ActiveContractMetricsColumnOptionIDs.FG_PERCENTAGE_ID, ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_MADE_ID,
    ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_ATTEMPTED_ID, ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_PERCENTAGE_ID,
    ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_MADE_ID, ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_ATTEMPTED_ID,
    ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_PERCENTAGE_ID, ActiveContractMetricsColumnOptionIDs.FREE_THROWS_MADE_ID,
    ActiveContractMetricsColumnOptionIDs.FREE_THROWS_ATTEMPTED_ID, ActiveContractMetricsColumnOptionIDs.FREE_THROWS_PERCENTAGE_ID,
    ActiveContractMetricsColumnOptionIDs.DRB_ID, ActiveContractMetricsColumnOptionIDs.ORB_ID, ActiveContractMetricsColumnOptionIDs.TRB_ID,
    ActiveContractMetricsColumnOptionIDs.AST_ID, ActiveContractMetricsColumnOptionIDs.STL_ID, ActiveContractMetricsColumnOptionIDs.TO_ID,
    ActiveContractMetricsColumnOptionIDs.BLK_ID, ActiveContractMetricsColumnOptionIDs.PF_ID];

export const METRICS_SHOTS_COLUMNS = [ActiveContractMetricsColumnOptionIDs.SHOOTING_ALL_ZONES_ID,
    ActiveContractMetricsColumnOptionIDs.SHOOTING_DP_ID, ActiveContractMetricsColumnOptionIDs.SHOOTING_THREES_ID,
    ActiveContractMetricsColumnOptionIDs.SHOOTING_NR2_ID, ActiveContractMetricsColumnOptionIDs.SHOOTING_C3_ID,
    ActiveContractMetricsColumnOptionIDs.SHOOTING_NC3_ID, ActiveContractMetricsColumnOptionIDs.SHOOTING_BLK_ID,
    ActiveContractMetricsColumnOptionIDs.SHOOTING_HP_ID, ActiveContractMetricsColumnOptionIDs.SHOOTING_NP2_ID];

export const METRICS_MODELED_SHOOTING = [ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_ALL_ZONES_ID,
    ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_DP_ID, ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_FT_ID,
    ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_L2_ID, ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_MR2_ID,
    ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_THREES_ID, ActiveContractMetricsColumnOptionIDs.METRICS_MODELED_SHOOTING_SPACING_ID,
]

export const ACTIVE_CONTRACT_COLUMNS = [
    {
        category: ActiveContractColumnCategories.GENERAL,
        groups: [
            {
                group: ActiveContractMetricsColumnCategoryGroups.GENERAL,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.BADGES_ID, label: ActiveContractMetricsColumnOptionLabels.BADGES_LABEL, checked: true, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.TEAM_ID, label: ActiveContractMetricsColumnOptionLabels.TEAM_LABEL, checked: true, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.AGENT_ID, label: ActiveContractMetricsColumnOptionLabels.AGENT_LABEL, checked: false, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.AGE_ID, label: ActiveContractMetricsColumnOptionLabels.AGE_LABEL, checked: true, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.POSITION_ID, label: ActiveContractMetricsColumnOptionLabels.POSITION_LABEL, checked: true, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.POSITION_GROUP_ID, label: ActiveContractMetricsColumnOptionLabels.POSITION_GROUP_LABEL, checked: false, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.CONTRACT_TYPE_ID, label: ActiveContractMetricsColumnOptionLabels.CONTRACT_TYPE_LABEL, checked: true, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.YOS_ID, label: ActiveContractMetricsColumnOptionLabels.YOS_LABEL, checked: true, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.FA_STATUS_ID, label: ActiveContractMetricsColumnOptionLabels.FA_STATUS_LABEL, checked: true, perms: null},
                ]
            },
            {
                group: ActiveContractMetricsColumnCategoryGroups.LOCS,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.LOC_NOW, label: ActiveContractMetricsColumnOptionLabels.LOC_NOW_LABEL, checked: true, perms: 'viewAllLOCs'},
                    {id: ActiveContractMetricsColumnOptionIDs.LOC_LOW, label: ActiveContractMetricsColumnOptionLabels.LOC_LOW_LABEL, checked: true, perms: 'viewAllLOCs'},
                    {id: ActiveContractMetricsColumnOptionIDs.LOC_BULL, label: ActiveContractMetricsColumnOptionLabels.LOC_BULL_LABEL, checked: true, perms: 'viewAllLOCs'},
                    {id: ActiveContractMetricsColumnOptionIDs.LOC_HIGH, label: ActiveContractMetricsColumnOptionLabels.LOC_HIGH_LABEL, checked: true, perms: 'viewAllLOCs'},
                ]
            },
            {
                group: ActiveContractMetricsColumnCategoryGroups.CONTRACT,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.XSALARY_ID, label: ActiveContractMetricsColumnOptionLabels.XSALARY_LABEL, checked: true, perms: null},
                    {id: ActiveContractMetricsColumnOptionIDs.DELTA_SALARY_ID, label: ActiveContractMetricsColumnOptionLabels.DELTA_SALARY_LABEL, checked: true, perms: null},
                ]
            },
        ]
    },
    {
        category: ActiveContractColumnCategories.METRICS,
        groups: [
            {
                group: ActiveContractMetricsColumnCategoryGroups.MEASURES,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.MSCORE_ID, label: ActiveContractMetricsColumnOptionLabels.MSCORE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.MPAG_ID, label: ActiveContractMetricsColumnOptionLabels.MPAG_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SA_ID, label: ActiveContractMetricsColumnOptionLabels.SA_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.POSS_ID, label: ActiveContractMetricsColumnOptionLabels.POSS_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.PPA_ID, label: ActiveContractMetricsColumnOptionLabels.PPA_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.EFG_ID, label: ActiveContractMetricsColumnOptionLabels.EFG_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.XEFG_ID, label: ActiveContractMetricsColumnOptionLabels.XEFG_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.QEFG_ID, label: ActiveContractMetricsColumnOptionLabels.QEFG_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.USG_ID, label: ActiveContractMetricsColumnOptionLabels.USG_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.FTA_100_ID, label: ActiveContractMetricsColumnOptionLabels.FTA_100_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.AST_RATE_ID, label: ActiveContractMetricsColumnOptionLabels.AST_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.TO_RATE_ID, label: ActiveContractMetricsColumnOptionLabels.TO_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.ORB_RATE_ID, label: ActiveContractMetricsColumnOptionLabels.ORB_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.DRB_RATE_ID, label: ActiveContractMetricsColumnOptionLabels.DRB_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.STL_RATE_ID, label: ActiveContractMetricsColumnOptionLabels.STL_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.BLK_RATE_ID, label: ActiveContractMetricsColumnOptionLabels.BLK_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.PF_RATE_ID, label: ActiveContractMetricsColumnOptionLabels.PF_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
                ]
            },
            {
                group: ActiveContractMetricsColumnCategoryGroups.BOX_SCORE,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.MPG_ID, label: ActiveContractMetricsColumnOptionLabels.MPG_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.PPG_ID, label: ActiveContractMetricsColumnOptionLabels.PPG_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.FG_PERCENTAGE_ID, label: ActiveContractMetricsColumnOptionLabels.FG_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_MADE_ID, label: ActiveContractMetricsColumnOptionLabels.TWO_POINTERS_MADE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_ATTEMPTED_ID, label: ActiveContractMetricsColumnOptionLabels.TWO_POINTERS_ATTEMPTED_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.TWO_POINTERS_PERCENTAGE_ID, label: ActiveContractMetricsColumnOptionLabels.TWO_POINTERS_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_MADE_ID, label: ActiveContractMetricsColumnOptionLabels.THREE_POINTERS_MADE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_ATTEMPTED_ID, label: ActiveContractMetricsColumnOptionLabels.THREE_POINTERS_ATTEMPTED_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.THREE_POINTERS_PERCENTAGE_ID, label: ActiveContractMetricsColumnOptionLabels.THREE_POINTERS_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.FREE_THROWS_MADE_ID, label: ActiveContractMetricsColumnOptionLabels.FREE_THROWS_MADE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.FREE_THROWS_ATTEMPTED_ID, label: ActiveContractMetricsColumnOptionLabels.FREE_THROWS_ATTEMPTED_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.FREE_THROWS_PERCENTAGE_ID, label: ActiveContractMetricsColumnOptionLabels.FREE_THROWS_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.DRB_ID, label: ActiveContractMetricsColumnOptionLabels.DRB_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.ORB_ID, label: ActiveContractMetricsColumnOptionLabels.ORB_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.TRB_ID, label: ActiveContractMetricsColumnOptionLabels.TRB_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.AST_ID, label: ActiveContractMetricsColumnOptionLabels.AST_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.STL_ID, label: ActiveContractMetricsColumnOptionLabels.STL_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.TO_ID, label: ActiveContractMetricsColumnOptionLabels.TO_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.BLK_ID, label: ActiveContractMetricsColumnOptionLabels.BLK_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.PF_ID, label: ActiveContractMetricsColumnOptionLabels.PF_LABEL, checked: false, perms: 'viewAllMetrics'}
                ],
            },
            {
            group: ActiveContractMetricsColumnCategoryGroups.SHOOTING,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_ALL_ZONES_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_ALL_ZONES_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_DP_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_DP_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_THREES_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_THREES_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_NR2_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_NR2_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_C3_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_C3_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_NC3_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_NC3_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_BLK_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_BLK_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_HP_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_HP_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.SHOOTING_NP2_ID, label: ActiveContractMetricsColumnOptionLabels.SHOOTING_NP2_LABEL, checked: false, perms: 'viewAllMetrics'},
                ]
            },
            {
                group: ActiveContractMetricsColumnCategoryGroups.MODELED_SHOOTING,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_ALL_ZONES_ID, label: ActiveContractMetricsColumnOptionLabels.MODELED_SHOOTING_ALL_ZONES_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_DP_ID, label: ActiveContractMetricsColumnOptionLabels.MODELED_SHOOTING_DP_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_THREES_ID, label: ActiveContractMetricsColumnOptionLabels.MODELED_SHOOTING_THREES_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_MR2_ID, label: ActiveContractMetricsColumnOptionLabels.MODELED_SHOOTING_MR2_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_L2_ID, label: ActiveContractMetricsColumnOptionLabels.MODELED_SHOOTING_L2_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.MODELED_SHOOTING_FT_ID, label: ActiveContractMetricsColumnOptionLabels.MODELED_SHOOTING_FT_LABEL, checked: false, perms: 'viewAllMetrics'},
                ]
            },
            {
                group: ActiveContractMetricsColumnCategoryGroups.PLAYING_TIME,
                options: [
                    {id: ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_POSITION_ID, label: ActiveContractMetricsColumnOptionLabels.PLAYING_TIME_POSITION_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_DEFENSIVE_MATCHUP_ID, label: ActiveContractMetricsColumnOptionLabels.PLAYING_TIME_DEFENSIVE_MATCHUP_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_ROTATIONS_ID, label: ActiveContractMetricsColumnOptionLabels.PLAYING_TIME_ROTATIONS_LABEL, checked: false, perms: 'viewAllMetrics'},
                    {id: ActiveContractMetricsColumnOptionIDs.PLAYING_TIME_GAME_MINUTES_ID, label: ActiveContractMetricsColumnOptionLabels.PLAYING_TIME_GAME_MINUTES_LABEL, checked: false, perms: 'viewAllMetrics'},
                ]
            }
        ]
    },

];

export const NO_AGENT_LABEL = 'No Agent'
export const METRICS_SEASON_START_MONTH_FROM_FG_HELPERS = 10
export const METRICS_SEASON_START_DAY_FROM_FG_HELPERS = 19

export const PLAYER_INTEREST_LEVEL_SCALE = [3, 2, 1, 0, -1, -2];
export const PLAYER_AVAILABILITY_LEVEL_SCALE = ['1st+', '1st', '2nd', 'Pos', 'Best', 'Dump'];

export const UPDATED_COLOR_PALETTE = [
    "#BF1E0A", "#EF3A23", "#FF8E80", "#FFB0A6", "#FFDDD9",
    "#FAFAFA", "#DBF0E5", "#A3D9BF", "#5EBC8E", "#429E71", "#36825D"
];

export const PLAYER_INTEREST_POST_TYPES = [
    3, 5, 6
]

export const TRADE_NOTE_ID = 6

export const EXCEPTION_ABBREV_MAPPING = {
    'Traded Player': 'TPE',
    'Traded Player Exception': 'TPE',
    'Room MLE': 'RMLE',
    'Bi-annual': 'BAE',
    'Bi-Annual Exception': 'BAE',
    'Convertible Non-Taxpayer MLE': 'CNTMLE',
    'Non-Taxpayer MLE': 'NTMLE',
    'Taxpayer MLE': 'TMLE',
    'Disabled Player': 'DPE',
}
