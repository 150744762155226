import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@services/auth.service';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter, Input,
} from '@angular/core';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {User} from '@models/users';
import {VideoService} from '@services/video.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SavedPlaylistDialogComponent} from '@bild-dialogs/saved-playlist/saved-playlist.component';
import {SavedFolderDialogComponent} from '@bild-dialogs/saved-folder/saved-folder.component';
import {ConfirmDialogComponent} from '@bild-dialogs/confirm-dialog/confirm-dialog.component';
import {take, filter} from 'rxjs/operators';
import * as _ from 'lodash';
import {RootStoreState} from 'apps/_store';
import {VideoStoreActions, VideoStoreSelectors} from '@store/video-store';
import {Store, select} from '@ngrx/store';
import {VideoNavigationTypes, VideoExploreTypes, VideoTabTypes, VideoSharingTypes,} from '@models/video';
import * as actions from '@store/video-store/actions';
import {Actions, ofType} from '@ngrx/effects';

enum PLAYLIST_SORTING {
  RECENT,
  ASC,
  DESC,
}

enum PLAYLIST_AUTHORS {
  ALL,
  MINE,
  SHARED,
}

@UntilDestroy()
@Component({
  selector: 'playlist-menu',
  templateUrl: './playlist-menu.component.html',
  styleUrls: ['./playlist-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
  })
export class PlaylistMenuComponent implements OnInit, OnDestroy {
  @ViewChild('playlistSearch') playlistSearch;
  @Input() isHotkeyMenu: boolean;
  @Input() isPostMenu: boolean;

  currentSorting: number = PLAYLIST_SORTING.RECENT;
  currentAuthor: number = PLAYLIST_AUTHORS.MINE;
  displayArchive: boolean = false;
  filteredPlaylists = [];
  filteredFolders = [];
  editableFolders = [];
  isSearchActive = false;
  user: User;
  playlists = [];
  folders = [];
  searchValue = '';
  selectedPlaylist;
  selectedFolder;
  sessionMetadata: any = null;
  chainID: any = null;
  deletedFolderIDs = [];
  deletedPlaylistIDs = [];

  readonly playlistAuthors = PLAYLIST_AUTHORS;
  readonly VideoSharingTypes = VideoSharingTypes;

  @Output() updateHotkey: EventEmitter<any> = new EventEmitter();
  @Output() updatePostMenuPlaylist: EventEmitter<any> = new EventEmitter();

  get sortingHeader() {
    switch (this.currentSorting) {
      case PLAYLIST_SORTING.RECENT:
        return 'Recent';
      case PLAYLIST_SORTING.ASC:
        return 'A -> Z';
      case PLAYLIST_SORTING.DESC:
        return 'Z -> A';
    }
  }

  get authorDisplay() {
    switch (this.currentAuthor) {
      case this.playlistAuthors.ALL:
        return 'All Authors';
      case this.playlistAuthors.MINE:
        return 'By you';
      case this.playlistAuthors.SHARED:
        return 'Shared';
    }
  }

  get archiveDisplay() {
    return this.displayArchive ? 'Archive' : 'Library';
  }

  constructor(
    protected actions$: Actions,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected router: Router,
    protected store$: Store<RootStoreState.State>,
    protected videoService: VideoService,
    protected authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.store$.pipe(
      select(VideoStoreSelectors.selectSession),
      untilDestroyed(this)
    ).subscribe(session => {
      this.sessionMetadata = _.cloneDeep(session);
      // this.setupSelectedPlaylist();
      this.cdr.markForCheck();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectPlaylists),
      filter(playlists => playlists?.isLoaded),
      untilDestroyed(this),
      ).subscribe(playlists => {
      if (playlists.playlists) {
        const filteredPlaylists = playlists.playlists.filter(playlist => !this.deletedPlaylistIDs.includes(playlist.id));
        this.updatePlaylists(_.cloneDeep(filteredPlaylists));
        this.cdr.markForCheck();
      }
    });

    this.videoService.selectedPlaylistSubject.pipe(untilDestroyed(this)).subscribe((playlist) => {
      this.changeSelectedPlaylist(playlist, false, (playlist && !playlist?.id));
    });

    this.actions$.pipe(
      ofType<actions.SavePlaylist>(actions.ActionTypes.SAVE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let existingPlaylistIndex = this.playlists.findIndex(playlist => playlist.id == payload.id);
      if (existingPlaylistIndex != -1) {
        this.playlists[existingPlaylistIndex] = {...this.playlists[existingPlaylistIndex], ...payload};
        this.updatePlaylists(_.cloneDeep(this.playlists));
      }
      else {
        this.playlists.unshift(payload);
        this.updatePlaylists(_.cloneDeep(this.playlists));
        if (this.chainID == chainID) {
          this.changeSelectedPlaylist(payload);
        }
      }
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.DeletePlaylist>(actions.ActionTypes.DELETE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.deletedPlaylistIDs.push(payload);
      this.updatePlaylists(this.playlists.filter(playlist => playlist.id != payload));
      this.updateFolders(_.cloneDeep(this.folders))
    });

    this.actions$.pipe(
      ofType<actions.ArchivePlaylist>(actions.ActionTypes.ARCHIVE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let archivedPlaylist = this.playlists.find(playlist => playlist.id === payload);
      archivedPlaylist.isArchived = true;
      this.filterPlaylists();
    });

    this.actions$.pipe(
      ofType<actions.UnarchivePlaylist>(actions.ActionTypes.UNARCHIVE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let unarchivedPlaylist = this.playlists.find(playlist => playlist.id === payload);
      unarchivedPlaylist.isArchived = false;
      this.filterPlaylists();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectFolders),
      filter(folders => folders?.isLoaded),
      untilDestroyed(this),
    ).subscribe(folders => {
      if (folders.folders) {
        const filteredFolders = folders.folders.filter(folder => !this.deletedFolderIDs.includes(folder.id));
        this.updateFolders(_.cloneDeep(filteredFolders));
        this.cdr.markForCheck();
      }
    });

    this.videoService.selectedFolderSubject.pipe(untilDestroyed(this)).subscribe((folder) => {
      this.changeSelectedFolder(folder);
    });

    this.actions$.pipe(
      ofType<actions.SaveFolder>(actions.ActionTypes.SAVE_FOLDER),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let existingFolderIndex = this.folders.findIndex(folder => folder.id == payload.id);
      if (existingFolderIndex != -1) {
        this.folders[existingFolderIndex] = {...this.folders[existingFolderIndex], ...payload};
        this.updateFolders(_.cloneDeep(this.folders));
      } else {
        this.folders.unshift(payload);
        this.updateFolders(_.cloneDeep(this.folders));
        if (this.chainID == chainID) {
          this.changeSelectedFolder(payload);
        }
      }
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.DeleteFolder>(actions.ActionTypes.DELETE_FOLDER),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.deletedFolderIDs.push(payload);
      const updatedFolders = this.folders.filter(folder => folder.id != payload);
      this.updateFolders(updatedFolders);
    });

    this.authService.currentUserData.pipe(take(1), untilDestroyed(this)).subscribe((user: User) => {
      this.user = user;
    });

    this.actions$.pipe(
      ofType<actions.ArchiveFolder>(actions.ActionTypes.ARCHIVE_FOLDER),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let archivedFolder = this.folders.find(folder => folder.id === payload);
      archivedFolder.isArchived = true;
      this.filterFolders(this.displayArchive);
    });

    this.actions$.pipe(
      ofType<actions.UnarchiveFolder>(actions.ActionTypes.UNARCHIVE_FOLDER),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let archivedFolder = this.folders.find(folder => folder.id === payload);
      archivedFolder.isArchived = false;
      this.filterFolders(this.displayArchive);
    });
  }

  updatePlaylists(playlists) {
    this.playlists = playlists;
    this.filteredPlaylists = _.cloneDeep(playlists);
    this.filterPlaylists();
    this.cdr.markForCheck();
  }

  updateFolders(folders: any) {
    this.folders = folders;
    this.folders.forEach(folder => {
      folder.playlists = folder.playlistIDs.map(playlistID => {
          return this.playlists.find(playlist => playlist?.isActive && playlist?.id === playlistID);
      }).filter(playlist => playlist !== undefined);
    });
    this.filteredFolders = _.cloneDeep(folders);
    this.filterFolderedPlaylists();
    this.filterFolders(this.displayArchive);
    this.editableFolders = _.cloneDeep(this.filteredFolders.filter(folder => this.canEditFolder(folder)));
    this.cdr.markForCheck();
  }

  createNewPlaylist() {
    const dialogConfig: MatDialogConfig = {
      data: {name: ''},
    };
    const dialogRef = this.dialog.open(SavedPlaylistDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe((newName) => {
      if (newName) {
        let newPlaylistData = {name: newName, sharedUsers: []};
        this.chainID = this.videoService.generateChainID();
        this.store$.dispatch(new actions.SaveAction({'playlistSaved': newPlaylistData, 'chainID': this.chainID}));
      }
    });
  }

  editShareFolder(folder: any) {
    this.videoService.updateFolder(folder);
  }

  canEditFolder(folder) {
    return folder?.id && folder.id != -1 && (folder.author.userID == this.user?.id || (folder.editability != VideoSharingTypes.PRIVATE && folder.sharedUsers.findIndex(sharedUser => sharedUser.userID == this.user?.id && sharedUser.isEditor) != -1));
  }

  canEditPlaylist(playlist) {
    return playlist?.id && playlist.id != -1 && (playlist.author.userID == this.user?.id || (playlist.editability != VideoSharingTypes.PRIVATE && playlist.sharedUsers.findIndex(sharedUser => sharedUser.userID == this.user?.id && sharedUser.isEditor) != -1));
  }

  canDeleteFolder(folder) {
    return folder?.id && folder.id != -1 && (folder.author.userID == this.user?.id);
  }

  canDeletePlaylist(playlist) {
    return playlist?.id && playlist.id != -1 && (playlist.author.userID == this.user?.id);
  }

  createNewFolder() {
    const dialogConfig: MatDialogConfig = {
      data: {name: ''},
    };
    const dialogRef = this.dialog.open(SavedFolderDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe((newName) => {
      if (newName) {
        let newFolderData = {name: newName, isArchived: this.displayArchive, sharedUsers: []};
        this.chainID = this.videoService.generateChainID();
        this.store$.dispatch(new actions.SaveAction({'folderSaved': newFolderData, 'chainID': this.chainID}));
      }
    });
  }

  deleteFolder(folder: any) {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Delete Folder',
        message: 'Are you sure you want to delete this folder?',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        this.store$.dispatch(new actions.SaveAction({'folderDeleted': folder, 'chainID': this.videoService.generateChainID()}));
      }
    });
  }

  deletePlaylist(playlist: any) {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Delete Playlist',
        message: 'Are you sure you want to delete this playlist?',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        this.store$.dispatch(new actions.SaveAction({'playlistDeleted': playlist, 'chainID': this.videoService.generateChainID()}));
      }
    });
  }

  archiveFolder(folder: any) {
    this.store$.dispatch(new actions.SaveAction({'folderArchived': folder, 'chainID': this.videoService.generateChainID()}));
    this.filterFolders(this.displayArchive);
  }

  unarchiveFolder(folder: any) {
    this.store$.dispatch(new actions.SaveAction({'folderUnarchived': folder, 'chainID': this.videoService.generateChainID()}));
    this.filterFolders(this.displayArchive);
  }

  archivePlaylist(playlist: any) {
    this.store$.dispatch(new actions.SaveAction({'playlistArchived': playlist, 'chainID': this.videoService.generateChainID()}));
    this.filterPlaylists();
  }

  unarchivePlaylist(playlist: any) {
    this.store$.dispatch(new actions.SaveAction({'playlistUnarchived': playlist, 'chainID': this.videoService.generateChainID()}));
    this.filterPlaylists();
  }

  removePlaylistFromFolder(playlist: any) {
    let bulkRemoveData = {
      playlists: [playlist],
      folder: this.selectedFolder,
    };
    this.store$.dispatch(new actions.SaveAction({'playlistRemovedFromFolder': bulkRemoveData}));
    this.filterPlaylists();
  }

  addPlaylistToFolder(playlist: any, folder: any) {
    let bulkSaveData = {
      playlists: [_.cloneDeep(playlist)],
      destinationFolders: [folder],
    };
    this.store$.dispatch(new actions.SaveAction({'playlistSavedToFolder': bulkSaveData, 'chainID': this.videoService.generateChainID()}));
    this.filterPlaylists();
    this.selectedFolder = folder;
  }

  filterPlaylists(authorValue?) {
    this.filterFolderedPlaylists();
    this.displayArchivedPlaylists();
    this.filterPlaylistsByName();
    this.filterAuthor(authorValue);
    this.cdr.markForCheck();
  }

  filterFolders(archiveSelected: boolean) {
    this.displayArchive = archiveSelected;
    if (archiveSelected) {
      this.filteredFolders = this.folders.filter(folder => folder.isArchived === true);
      this.editableFolders = _.cloneDeep(this.filteredFolders.filter(folder => this.canEditFolder(folder)));
    } else {
      this.filteredFolders = this.folders.filter(folder => folder.isArchived === false);
      this.editableFolders = _.cloneDeep(this.filteredFolders.filter(folder => this.canEditFolder(folder)));
    }
    this.filterPlaylists();
  }
  
  filterAuthor(filterValue?) {
    if (filterValue != null) {
      this.currentAuthor = filterValue;
    }

    let authorSharedValue;

    const isArchivedValue = this.displayArchive;
    this.filteredFolders = this.folders.filter(folder => folder.isArchived === isArchivedValue);
    this.editableFolders = _.cloneDeep(this.filteredFolders.filter(folder => this.canEditFolder(folder)));

    switch (this.currentAuthor) {
      case this.playlistAuthors.ALL:
        return this.filteredPlaylists;
      case this.playlistAuthors.SHARED:
        authorSharedValue = true;
        break;
      case this.playlistAuthors.MINE:
        authorSharedValue = false;
        break;
    }

    this.filteredPlaylists = _.filter(this.filteredPlaylists, (playlist) => {
      return playlist.isShared == authorSharedValue;
    });
  
    if (this.currentAuthor !== this.playlistAuthors.ALL) {
      this.filteredFolders = _.filter(this.filteredFolders, (folder) => {
        return folder.isShared === authorSharedValue;
      });
    }
  }  

  filterPlaylistsByName() {
    if (this.searchValue === '') {
      this.filteredPlaylists = _.cloneDeep(this.filteredPlaylists);
    } else {
      const searchValue = this.searchValue.toLowerCase();
      this.filteredPlaylists = _.filter(this.filteredPlaylists, (playlist) => {
        return playlist.name.toLowerCase().includes(searchValue);
      });
    }
  }

  filterFolderedPlaylists() {
    const folderedPlaylistIDs = this.folders.reduce((acc, folder) => {
      return acc.concat(folder.playlistIDs);
    }, []);
    this.filteredPlaylists = this.playlists.filter(playlist => !folderedPlaylistIDs.includes(playlist.id));    
    this.cdr.markForCheck();
  }

  displayArchivedPlaylists() {
    this.filteredPlaylists = this.filteredPlaylists.filter(playlist => (this.displayArchive ? playlist.isArchived : !playlist.isArchived));
    this.cdr.markForCheck();
  }

  reorderPlaylists(ascending) {
    if (ascending != null) {
      this.filteredPlaylists = _.orderBy(this.filteredPlaylists, ['name'], ascending ? 'asc' : 'desc');
      this.filteredFolders = _.orderBy(this.filteredFolders, ['name'], ascending ? 'asc' : 'desc');
    } else {
      this.filteredPlaylists = _.cloneDeep(this.playlists);
      this.filteredPlaylists = _.cloneDeep(this.folders);
      this.filterFolders(this.displayArchive);
      this.filterPlaylists();
    }
  }

  toggleSorting() {
    switch (this.currentSorting) {
      case PLAYLIST_SORTING.RECENT:
        this.currentSorting = PLAYLIST_SORTING.ASC;
        this.reorderPlaylists(true);
        break;
      case PLAYLIST_SORTING.ASC:
        this.currentSorting = PLAYLIST_SORTING.DESC;
        this.reorderPlaylists(false);
        break;
      case PLAYLIST_SORTING.DESC:
        this.currentSorting = PLAYLIST_SORTING.RECENT;
        this.reorderPlaylists(null);
        break;
    }
  }

  setSearchActive(val, event?) {
    if (val || event.relatedTarget?.classList?.contains('playlist-search')) {
      this.isSearchActive = true;
      setTimeout(() => {
        this.playlistSearch.nativeElement.focus();
        this.cdr.markForCheck();
      }, 20);
    } else if (this.searchValue == '') {
      this.isSearchActive = false;
    }
  }

  findActivePlaylist(playlist) {
    switch (playlist) {
      case this.sessionMetadata.playlist1ID:
        return VideoNavigationTypes.PLAYLIST_1;
      case this.sessionMetadata.playlist2ID:
        return VideoNavigationTypes.PLAYLIST_2;
      case this.sessionMetadata.playlist3ID:
        return VideoNavigationTypes.PLAYLIST_3;
      case this.sessionMetadata.playlist4ID:
        return VideoNavigationTypes.PLAYLIST_4;
      case this.sessionMetadata.playlist5ID:
        return VideoNavigationTypes.PLAYLIST_5;
      case this.sessionMetadata.playlist6ID:
        return VideoNavigationTypes.PLAYLIST_6;
    }
  }

  changeSelectedPlaylist(playlist, forwardUpdate = true, allSavedVideos = false) {
    if (playlist == null && allSavedVideos) {
      playlist = {id: -1, name: 'All Saved Videos'};
    }
    this.selectedPlaylist = playlist;
    if (this.isHotkeyMenu && forwardUpdate) {
      this.updateHotkey.emit(playlist);
    } else if (this.isPostMenu) {
      this.updatePostMenuPlaylist.emit(playlist);
    } else if (forwardUpdate) {
      this.videoService.selectedPlaylistSubject.next(playlist);
      this.videoService.selectedTabSubject.next(VideoTabTypes.BROWSE_VIDEO);
      if (playlist?.id && [this.sessionMetadata.playlist1ID, this.sessionMetadata.playlist2ID, this.sessionMetadata.playlist3ID,
        this.sessionMetadata.playlist4ID, this.sessionMetadata.playlist5ID, this.sessionMetadata.playlist6ID].includes(playlist.id)) {
        this.store$.dispatch(new actions.SaveAction({'navigationChange': this.findActivePlaylist(playlist.id)}));
      } else {
        this.store$.dispatch(new actions.SaveAction({'exploreTypeChange': VideoExploreTypes.PLAYLIST, 'chainID': this.videoService.generateChainID()}));
        this.store$.dispatch(new actions.SaveAction({'explorePlaylistChange': {id: this.selectedPlaylist?.id, 'chainID': this.videoService.generateChainID()}}));
        this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE, 'chainID': this.videoService.generateChainID()}));
      }
    }

    const folder = this.folders.find(folder => folder.playlistIDs?.includes(playlist?.id));

    if (this.selectedFolder?.playlistIDs?.includes(playlist?.id))  {
      this.videoService.updateFolderPath(this.selectedFolder.name);
    } else if (folder) {
      this.videoService.updateFolderPath(folder.name);
    } else {
      this.videoService.updateFolderPath('');
    }
    this.cdr.markForCheck();
  }

  changeSelectedFolder(folder) {
    this.selectedFolder = this.selectedFolder?.id === folder?.id ? null : folder;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
  }
}
