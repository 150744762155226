import * as _ from 'lodash';

import {ElementRef, Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {parseURL, URL} from 'universal-parse-url';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Sort} from '@angular/material/sort';
import {sortResults} from '../bild/entities/entity-metrics/_helpers/table.helper';
import * as moment from "moment-timezone";


@UntilDestroy()
@Injectable()
export class DOMHelper {
  currentUrl: URL;

  constructor(protected router: Router) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = parseURL(event.url);
      }
    });
  }

  isElementInViewport(elementRef: ElementRef): boolean {
    const rect = elementRef.nativeElement.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
  }

  isLinkActive(url: (number|string)[]): boolean {
    if (!url) {
      return false;
    }

    // Map all  to strings in-case some are numbers
    url = url.map(String);
    if (url.join('/') === this.currentUrl.pathname || url.join('/') === this.currentUrl.pathname.substr(0, this.currentUrl.pathname.length - 1)) {
      return true;
    } else if (this.currentUrl.pathname === '/' && _.isEqual(url, ['', 'bild'])) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'focus-groups') && url.includes('focus-groups')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'metrics') && url.includes('metrics') && !_.includes(this.currentUrl.pathname, 'physical')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'physical') && url.includes('physical')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'scoreboard') && url.includes('scoreboard')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'development') && url.includes('development')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'player-development') && url.includes('player-development')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'psych') && url.includes('psych')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'evaluation') && url.includes('evaluation')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'events') && url.includes('events')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'sportscode') && url.includes('sportscode')) {
      return true;
    // } else if (_.includes(this.currentUrl.pathname, 'video') && url.includes('video')) {
    //   return true;
    } else if (_.includes(this.currentUrl.pathname, 'shot-charting-legacy') && url.includes('shot-charting')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'sportscode') && url.includes('sportscode')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'contract/team') && _.includes(url.join('/'), 'contract/team')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'contract/league') && _.includes(url.join('/'), 'contract/league')) {
      return true;
    } else if (_.includes(this.currentUrl.pathname, 'contract/players') && _.includes(url.join('/'), 'contract/players')) {
      return true;
    } else {
      return false;
    }
  }

  hideInitialLoading(elementRef: ElementRef): void {
    let lookup = elementRef.nativeElement.parentElement;
    while (lookup != null) {
      if (lookup.tagName === 'BODY') {
        for (const child of lookup.children) {
          if (child.id === 'initial-loader') {
            child.remove();
            return;
          }
        }
      }
      lookup = lookup.parentElement;
    }
  }

  highlightClickedWord() {
    const s = window.getSelection();
    const range = s.getRangeAt(0);
    const node = s.anchorNode;

    // Find the start of the word
    while (range.startOffset > 0 && !/\s/.test(range.toString().charAt(0))) {
      range.setStart(node, (range.startOffset - 1));
    }
    range.setStart(node, range.startOffset === 0 ? 0 : range.startOffset + 1);

    // Find the end of the word
    while (!/\s/.test(range.toString()) && range.endOffset < node['length']) {
      range.setEnd(node, range.endOffset + 1);
    }
  }

  arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  sortHistoryData(sort: Sort, originalData) {
    let data = _.cloneDeep(originalData);
    data = sortResults(data, sort.active, sort.direction);
    let currentIndex = 0;
    const teamsVisited = new Set();
    while (currentIndex < data?.length) {
      const row = data[currentIndex];
      if (row.isHidden) {
        const totalRowIndex = _.findIndex(data, (d: any) => {
          return d.season === row.season && d.isTotal;
        });
        let targetIndex;
        if (totalRowIndex > currentIndex) {
          targetIndex = totalRowIndex;
        } else {
          targetIndex = totalRowIndex + 1;
        }
        this.arrayMove(data, currentIndex, targetIndex);
        if (currentIndex == targetIndex || teamsVisited.has(row.teamAbbrev)) {
          currentIndex++;
          teamsVisited.clear();
        } else {
          teamsVisited.add(row.teamAbbrev);
        }
      } else {
        currentIndex++;
        teamsVisited.clear();
      }
    }
    return data;
  }

  seedToOrdinal(seedField) {
    let ordinal = '';
    let seedNum = parseInt(seedField.substring(1));
    if (seedNum % 10 == 1 && seedNum != 11) {
      ordinal = seedNum + 'st';
    }
    else if (seedNum % 10 == 2 && seedNum != 12) {
      ordinal = seedNum + 'nd';
    }
    else if (seedNum % 10 == 3 && seedNum != 13) {
      ordinal = seedNum + 'rd';
    }
    else {
      ordinal = seedNum + 'th';
    }
    return ordinal;
  }

  getDisplayPct(pct: number, displayCheck?: boolean) {
    let displayPct;
    if (pct == 1 && displayCheck) {
      displayPct = '\u2713';
    }
    else if (pct * 100 < 1) {
      displayPct = '<1%';
    }
    else if (pct * 100 > 99) {
      displayPct = '>99%';
    }
    else {
      displayPct = Math.round(pct * 100) + '%';
    }
    return displayPct;
  }

  getTimeSince(createDatetime, nowDatetime = null): string {
    if (!nowDatetime) {
      nowDatetime = moment();
    }
    const duration = moment.duration(nowDatetime.diff(createDatetime));
    const durationSeconds = Math.floor(duration.asSeconds());
    if (durationSeconds < 60) {
      return durationSeconds + 's ago';
    }
    const durationMinutes = Math.floor(duration.asMinutes());
    if (durationMinutes < 60) {
      return durationMinutes + 'm ago';
    }
    const durationHours = Math.floor(duration.asHours());
    if (durationHours < 24) {
      return durationHours + 'hr' + (durationHours == 1 ? '' : 's') + ' ago';
    }
    const durationDays = Math.floor(duration.asDays());
    return durationDays + ' day' + (durationDays == 1 ? '' : 's') + ' ago';
  }

  pushNullsToBottom(sortDirection: string): string {
    return sortDirection === 'desc' ? '\u0000' : '\u{10FFFF}';
  }

  pushNullNumbersToBottom(sortDirection: string): number {
    return sortDirection === 'desc' ? -Infinity : Infinity;
  }

  splitCamelCase(str: string): string {
    if (str) {
      return str.split(/(?=[A-Z])/).join(' ');
    }
  }

  capitalizeFirstLetter(str: string, multipleWords = false): string {
    if (str?.length && !multipleWords) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else if (str?.length) {
      const wordArray = str.split(' ');
      return _.map(wordArray, (word) => this.capitalizeFirstLetter(word)).join(' ');
    }
  }

  isQuarterChange(secondsIntoGame: number): boolean {
    // 2880 is length of regulation, 720 is quarter length and 300 is overtime period length
    if (secondsIntoGame >= 2880) {
      return (secondsIntoGame - 2880) % 300 == 0;
    } else {
      return secondsIntoGame % 720 == 0;
    }
  }
}
