
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {AutocompleteService} from '@services/autocomplete.service';

@UntilDestroy()
@Injectable()
export class AtjsHelper {
  THUNDER_ID = 2;
  STAFF_SUBTYPE_ID = 3;

  constructor(protected autocompleteService: AutocompleteService) {}

  buildTpl(entity, size: number = 36): string {
    const sizePx = `${size}px`;
    const cornerRadius = size / 2;
    const subName = this.buildSubName(entity);
    const extra = this.buildExtra(entity);

    const tpl = `
        <img srcset="${entity.original.optimizedImageUrl}&h=${size}&w=${size}&corner-radius=${cornerRadius}&dpr=1 1x,${entity.original.optimizedImageUrl}&h=${size}&w=${size}&corner-radius=${cornerRadius}&dpr=2 2x,${entity.original.optimizedImageUrl}&h=${size}&w=${size}&corner-radius=${cornerRadius}&dpr=3 3x"
                     src="${entity.original.optimizedImageUrl}&h=${size}&w=${size}&corner-radius=${cornerRadius}"/>
                <div class="name" style="height: ${sizePx}">
                    <span>${entity.original.name}</span>
                    <small>${subName}</small>
                </div>
                <div class="extra" style="height: ${sizePx}">
                    <span>${extra}</span>
                </div>`;

    return tpl;
  }

  buildTemplate(entity): string {
    const entityLinkType = entity.original.type == 'Person' ? 'people' : 'teams';
    const subtype = entity.original.subtype ? entity.original.subtype.id : '';
    const affiliationID = entity.original.affiliation?.id ? entity.original.affiliation.id : '';
    return `<a data-entity-id="${entity.original.id}" data-entity-name="${entity.original.name}" data-entity-type="${entity.original.type}" data-entity-subtype="${subtype}" data-entity-imageUrl="${entity.original.imageUrl}" data-affiliation-id="${affiliationID}" href="bild/entities/${entityLinkType}/${entity.original.id}">${entity.original.name}</a>`;
  }

  buildHashtagTpl(hashtag): string {
    let html = `<span class="term">#${hashtag.term}</span>`;

    if (hashtag.isKeyResult) {
      html = `${html}<small>KeyResult</small>`;
    }

    return html;
  }

  getEntityMentions(q, cb) {
    if (q.length > 2) {
      this.autocompleteService.getElasticSearchResults(q).pipe(take(1), untilDestroyed(this)).subscribe(
        entities => {
          // Unpack so it will work with at.js
          const ents: Array<{}> = [];
          entities.forEach(function(entity) {
            ents.push({'id': entity.id, 'name': entity.name, 'optimizedImageUrl': entity.optimizedImageUrl,
              'age': entity.age, 'location': entity.location, 'type': entity.type, 'affiliation': entity.affiliation,
              'league': entity.league, 'matched': q, 'subtype': entity.subtype, 'imageUrl': entity.imageUrl});
          });
          cb(ents);
        }
      );
    }
  }

  getHashtags(q, cb) {
    this.autocompleteService.getHashtags(q).pipe(take(1), untilDestroyed(this)).subscribe(
      data => {
        // Unpack so it will work with at.js
        const hashtags: Array<{}> = [];
        data.forEach(function(hashtag) {
          hashtags.push({'id': hashtag.id, 'term': hashtag.term, 'isKeyResult': hashtag.isKeyResult, 'matched': q});
        });
        cb(hashtags);
      }
    );
  }

  buildSubName(entity): string {
    entity = entity.original;
    let subName: string = '';
    switch (true) {
      case !!(entity.type === 'Person' && entity.affiliation?.name):
        subName = entity.affiliation.name;
        break;
      case !!(entity.type === 'Team' && entity.location):
        subName = entity.location;
        break;
    }

    return subName;
  }

  buildExtra(entity): string {
    entity = entity.original;
    let extra: string = '';
    switch (true) {
      // Hide age on thunder staff members
      case !!(entity.type === 'Person' && entity.age && !(entity.affiliation && entity.affiliation.id === this.THUNDER_ID && entity.subtype && entity.subtype.id === this.STAFF_SUBTYPE_ID)):
        extra = entity.age;
        break;
      case !!(entity.type === 'Team' && entity.league):
        extra = entity.league.abbrev;
        break;
    }

    return extra;
  }
}
